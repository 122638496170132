import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { setExternalViews } from '@store/actionSlices/externalViews'
import { RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'

import {
  ExternalViewsData,
  selectFromResult,
  useGetExternalViewsQuery,
} from '@api/external-views'

export interface ExternalViewsProps {
  projectName: string
  externalViews: Array<ExternalViewsData>
  session: SessionMap | undefined
}

const ExternalViews = ({
  projectName,
  externalViews,
  session,
}: ExternalViewsProps) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [selectedView, setSelectedView] = useState(0)

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName },
    { selectFromResult }
  )

  const setExternalView = () => {
    if (session) {
      setSelectedView(session.externalView || 0)
      return
    }

    const { state } = location
    if (state !== undefined) {
      setSelectedView(state)
      return
    }

    setSelectedView(0)
  }

  React.useEffect(() => {
    const { externalViewsData } = externalViewsPayload
    if (externalViews.length === 0 && externalViewsData.length > 0) {
      dispatch(setExternalViews(externalViewsData))
    }
  }, [externalViewsPayload, externalViews])

  React.useEffect(() => {
    setExternalView()
  }, [location, session])

  return (
    <DataHandler
      payload={{
        ...externalViewsPayload,
        data: externalViews.length > 0,
        apiData: externalViewsPayload.externalViewsData,
      }}
    >
      <Container>
        <iframe
          key={selectedView}
          className="z-0 h-full w-full overflow-hidden border-0"
          title={externalViews[selectedView]?.title}
          src={externalViews[selectedView]?.url}
          height="100%"
          width="100%"
        />
      </Container>
    </DataHandler>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    externalViews,
    firestore: { session },
  }: RootStateTypeExtra) => ({
    projectName,
    externalViews,
    session,
  })
)(ExternalViews)

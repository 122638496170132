import { BuildingInterface } from '@store/actionSlices/building'
import { UnitFilterInterface } from '@store/types'

import { Level, Unit } from '@api/building'

import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

const getFilteredBuildingData = (
  building: BuildingInterface,
  unitFilter: UnitFilterInterface,
  showPrice: boolean,
  blockCount: number,
  availableStatusLabel: string
): Array<Level> => {
  const levelList: Level[] = [...building.levels]
  const { apply: filterApplied, anyLevel } = unitFilter
  const { activeLevel: filteredLevel, activeBlock: filteredBlock } = building
  let filterLevelList = levelList
    .map((unit) => ({
      ...unit,
      data:
        blockCount > 1
          ? unit.data.filter((item) => item.blockId === filteredBlock)
          : unit.data,
    }))
    .filter((unit) => unit.data.length)
  if (!filterApplied) return filterLevelList
  if (!anyLevel) {
    filterLevelList = filterLevelList.filter(
      (item: Level) => item.level === filteredLevel
    )
  }
  const filteredLevels: Level[] = []
  filterLevelList.forEach((item: Level) => {
    let myUnits = item.data
    if (blockCount > 1 && filteredBlock) {
      myUnits = myUnits.filter((u: Unit) => u.blockId === filteredBlock)
    }
    myUnits = myUnits.filter((u: Unit) =>
      filterUnitUtil(u, unitFilter, showPrice, availableStatusLabel)
    )
    if (myUnits.length > 0) {
      filteredLevels.push({ ...item, data: myUnits })
    }
  })
  return filteredLevels
}
export default getFilteredBuildingData

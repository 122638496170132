import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { selectHouseAndLand, setByFlag } from '@store/actionSlices/houseAndLand'
import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  RootStateTypeExtra,
} from '@store/types'

import { StatusLabels } from '@api/config'
import {
  HouseAndLandActiveKeyType,
  LotInterface,
  PackageInterface,
  PackageOptionsInterface,
} from '@api/types/house-and-land-type'

import LotCardHeader from './lot-card-header'
import LotConfiguration from './lot-configuration'
import LotPackage, { LotPackageProps } from './lot-package'
import ShortlistAction from './shortlist-action'

export interface LotCardProps {
  lot: LotInterface
  stageInfo: { id: string; label: string }
  activePrecinctId: string
  packages: Array<PackageInterface>
  activeStageId: string
  active: boolean
  shortlists: ShortlistState['shortlists']
  // shortlists: Array<LotShortlistInterface>
  disableSoldLot: boolean
  packageOptions: Array<PackageOptionsInterface>
  statusLabels: StatusLabels
  onConfigurationValueChange?: (
    values: PackageConfigurationInterface,
    type?: keyof PackageConfigurationInterface,
    value?: string
  ) => void
  onConfigurationOptionSelect?: (data: {
    propertyType: keyof PackageConfigurationInterface
    value: string
  }) => void
  onConfigurationPropertyExpand?: (data: {
    propertyType: keyof PackageConfigurationInterface
    value: string
    expanded: boolean
  }) => void
}

const INITIAL_PACKAGE_CONFIGURATION: PackageConfigurationInterface = {
  floorplan: '',
  floorplanOption: '',
  facade: '',
  facadeColor: '',
  internalTheme: '',
  internalColorScheme: '',
}

const LotCard = ({
  lot,
  packageOptions,
  activeStageId,
  stageInfo,
  activePrecinctId,
  packages,
  active = false,
  shortlists,
  disableSoldLot,
  statusLabels,
  onConfigurationValueChange,
  onConfigurationOptionSelect,
  onConfigurationPropertyExpand,
}: LotCardProps) => {
  const dispatch = useDispatch()
  const { activePackageId } = useSelector(selectHouseAndLand)
  const defaultFloorplanId = React.useMemo(
    () => (packages.length > 0 ? packages[0].id : ''),
    [packages]
  )
  const validActivePackageId = React.useMemo(
    () => packages.find((item) => item.id === activePackageId)?.id,
    [activePackageId, packages]
  )

  const [packageConfiguration, setPackageConfiguration] =
    React.useState<PackageConfigurationInterface>({
      ...INITIAL_PACKAGE_CONFIGURATION,
      floorplan: active ? validActivePackageId || defaultFloorplanId : '',
    })

  const { isShortlisted, isLotShortlisted, shortlistedConfiguration } =
    React.useMemo(() => {
      const matchedShortlist = (shortlists as LotShortlistInterface[]).find(
        (item) =>
          item.propertyId === lot.id &&
          (item.configuration?.floorplan ?? '') ===
            (packageConfiguration.floorplan ?? '') &&
          (item.configuration?.floorplanOption ?? '') ===
            (packageConfiguration.floorplanOption ?? '') &&
          (item.configuration?.facade ?? '') ===
            (packageConfiguration.facade ?? '') &&
          (item.configuration?.facadeColor ?? '') ===
            (packageConfiguration.facadeColor ?? '') &&
          (item.configuration?.internalTheme ?? '') ===
            (packageConfiguration.internalTheme ?? '') &&
          (item.configuration?.internalColorScheme ?? '') ===
            (packageConfiguration.internalColorScheme ?? '')
      )

      const matchedLot = shortlists.find((item) => item.propertyId === lot.id)

      return {
        isShortlisted: !!matchedShortlist,
        isLotShortlisted: !!matchedLot,
        shortlistedConfiguration: matchedShortlist?.configuration,
      }
    }, [shortlists, lot.id, packageConfiguration])

  const activePackage = React.useMemo(
    () =>
      active
        ? packages.find((pkg) => pkg.id === packageConfiguration.floorplan)
        : undefined,
    [packageConfiguration?.floorplan, active, packages]
  )

  const disable = React.useMemo(() => {
    if (disableSoldLot && lot.status !== statusLabels?.available) {
      return true
    }
    return false
  }, [lot.status, disableSoldLot])

  const handleClick = React.useCallback(() => {
    if (activeStageId !== stageInfo.label) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveStageId,
          value: stageInfo.label,
        })
      )
    }
    dispatch(
      setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: lot.id })
    )
  }, [lot.id, stageInfo.label, activeStageId])

  const handleConfigurationPropertyExpand = React.useCallback<
    NonNullable<LotPackageProps['onExpandProperty']>
  >(
    ({ propertyType, expanded }) => {
      onConfigurationPropertyExpand?.({
        propertyType,
        expanded,
        value: packageConfiguration[propertyType] || '',
      })
    },
    [onConfigurationPropertyExpand, packageConfiguration]
  )

  const handleChangePackageConfiguration = React.useCallback(
    (propertyType: keyof PackageConfigurationInterface, value: string) => {
      onConfigurationOptionSelect?.({ propertyType, value })
      let updatedConfiguration: PackageConfigurationInterface = {
        ...packageConfiguration,
        [propertyType]: value,
      }
      if (propertyType === 'floorplan') {
        updatedConfiguration = {
          ...INITIAL_PACKAGE_CONFIGURATION,
          [propertyType]: value,
        }
        dispatch(
          setByFlag({
            flag: HouseAndLandActiveKeyType.ActivePackageId,
            value,
          })
        )
      }
      if (propertyType === 'facade') {
        updatedConfiguration = {
          ...packageConfiguration,
          [propertyType]: value,
          facadeColor: '',
        }
      }
      if (propertyType === 'internalTheme') {
        updatedConfiguration = {
          ...packageConfiguration,
          [propertyType]: value,
          internalColorScheme: '',
        }
      }
      setPackageConfiguration(updatedConfiguration)
    },
    [onConfigurationOptionSelect, packageConfiguration, lot.id, isShortlisted]
  )

  React.useEffect(() => {
    if (isShortlisted) {
      setPackageConfiguration((curr) => ({ ...curr, shortlistedConfiguration }))
    }
  }, [])

  React.useEffect(() => {
    if (active) {
      setPackageConfiguration((prevVal) => ({
        ...prevVal,
        floorplan: validActivePackageId || '',
        ...(isShortlisted && shortlistedConfiguration),
      }))
      return
    }
    setPackageConfiguration({
      ...INITIAL_PACKAGE_CONFIGURATION,
      ...(isShortlisted && shortlistedConfiguration),
    })
  }, [active, validActivePackageId, isShortlisted])

  React.useEffect(() => {
    onConfigurationValueChange?.(packageConfiguration)
  }, [onConfigurationValueChange, packageConfiguration])

  return (
    <div
      role="none"
      id={lot.id}
      className={`overflow-hidden rounded-md transition-[border] ${
        active ? 'border-l-[7px] border-mainColour' : 'cursor-pointer'
      } bg-white ${disable ? 'pointer-events-none !bg-[#c2c2c2]' : ''}`}
      onClick={active ? undefined : handleClick}
    >
      <div className="px-6 py-4">
        <LotCardHeader
          lot={lot}
          totalPackage={packages.length}
          stageId={stageInfo.label}
          precinctId={activePrecinctId}
          isShortlisted={isLotShortlisted}
          totalShortlist={shortlists.length}
          availableStatusLabel={statusLabels?.available}
        />
        <LotConfiguration
          {...(activePackage?.configuration || {})}
          area={lot.area}
          frontage={lot.frontage}
        />
        <CSSTransition
          in={active}
          classNames="transition-lot-package-expand"
          timeout={500}
          unmountOnExit
        >
          <div className="overflow-hidden">
            <div className="mt-4 border-t-2 pb-2 pt-4">
              {packages.length ? (
                <LotPackage
                  packages={packages}
                  packageOptions={packageOptions}
                  values={packageConfiguration}
                  onChange={handleChangePackageConfiguration}
                  onExpandProperty={handleConfigurationPropertyExpand}
                />
              ) : null}
            </div>
            <ShortlistAction
              actionType="button"
              lot={lot}
              stageId={stageInfo.label}
              precinctId={activePrecinctId}
              isShortlisted={isShortlisted}
              isLotShortlisted={isLotShortlisted}
              totalShortlist={shortlists.length}
              packageConfiguration={packageConfiguration}
              availableStatusLabel={statusLabels?.available}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}
export default connect(
  ({
    shortlist: { shortlists },
    projectConfig: { disableSoldUnit, statusLabels },
    houseAndLand: { packages, activeStageId, activePrecinctId },
  }: RootStateTypeExtra) => ({
    shortlists,
    disableSoldLot: disableSoldUnit, // TODO: change it to disableSoldLot when BE has it done
    packageOptions: packages,
    activeStageId,
    activePrecinctId,
    statusLabels,
  })
)(LotCard)

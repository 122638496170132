import React from 'react'
import { connect } from 'react-redux'

import { PackageConfigurationInterface, RootStateTypeExtra } from '@store/types'

import LotConfiguration from '@pages/stage/lots-side-panel/lot-card/lot-configuration'

import {
  LotInterface,
  PackageOptionsInterface,
} from '@api/types/house-and-land-type'

import AssetHandler from '@utilities/asset-handler'
import { formatPrice } from '@utilities/helper'
import useParseLotPackageConfiguration from '@utilities/lot-package-util'

import { StarSvg } from '@svg/react'

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

interface LotThumbnailProps {
  lot: LotInterface
  package?: PackageOptionsInterface
  configuration?: PackageConfigurationInterface
  onRemove: () => void
  isFirstItem: boolean
  showPrice: boolean
  projectId: string
}

const LotThumbnail = ({
  lot,
  package: lotPackage,
  configuration,
  onRemove,
  isFirstItem,
  showPrice,
  projectId,
}: LotThumbnailProps) => {
  const [parsePackageConfiguration] = useParseLotPackageConfiguration()
  const { photo, price, configurationTitle } = React.useMemo(
    () =>
      parsePackageConfiguration({
        lot,
        package: lotPackage,
        configuration,
        projectId,
      }),
    [parsePackageConfiguration, lot, lotPackage, configuration, projectId]
  )

  const handleRemove = React.useCallback(
    (e) => {
      e.stopPropagation()
      onRemove()
    },
    [onRemove]
  )

  return (
    <div className="relative w-[350px] overflow-hidden border-b-2 border-gray-300 last:rounded-b-md last:border-0">
      {isFirstItem && (
        <div className="absolute left-4.5 top-4.5 flex items-center rounded-[100px] bg-white px-2 py-1.5 shadow-md">
          <div className="mr-1.5 inline-block h-3 w-3 rounded-full bg-green-500" />
          <span className="font-bold leading-none">Lot {lot.name}</span>
        </div>
      )}
      {isFirstItem && (
        <img
          className="h-[145px] w-full rounded-t-md object-cover"
          alt="thumbnail"
          src={AssetHandler({
            url: String(photo.url || PLACEHOLDER_IMAGE),
            type: photo?.config?.type || 'new',
            staticUrl: photo?.config?.staticUrl ?? true,
            noSpliceUrl: photo?.config?.noSpliceUrl ?? false,
          })}
        />
      )}
      <div className="flex flex-col gap-1.5 bg-white px-4.5 py-5">
        {showPrice && (
          <div className="flex items-center justify-between">
            <p className="text-[17px] font-semibold leading-none tracking-[0.17px] text-gray-900">
              {formatPrice(price)}
            </p>

            <button type="button" title="Shortlist lot" onClick={handleRemove}>
              <StarSvg className="h-4 w-4 stroke-amber-500 text-amber-500" />
            </button>
          </div>
        )}
        <div className="flex items-center justify-between gap-4">
          <LotConfiguration
            {...lotPackage?.configuration}
            area={lot.area}
            frontage={lot.frontage}
          />
          {!showPrice && (
            <button
              type="button"
              title="Shortlist unit"
              onClick={handleRemove}
              className="self-start pt-0.5"
            >
              <StarSvg className="h-4 w-4 stroke-amber-500 text-amber-500" />
            </button>
          )}
        </div>{' '}
        {configurationTitle ? (
          <div className="mt-2 break-words border-t py-3">
            <p className="italic">{configurationTitle}</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity: { projectId },
    projectConfig: { showPrice },
  }: RootStateTypeExtra) => ({
    showPrice,
    projectId,
  })
)(LotThumbnail)

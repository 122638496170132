import { Slide, SlideProps } from 'pure-react-carousel'
import React from 'react'
import { connect } from 'react-redux'

import { RootStateTypeExtra } from '@store/types'

export type GallerySlideInterface = SlideProps & {
  transitionType?: string
  transitionTypeConfig?: string
}

const GallerySlide = ({
  children,
  transitionType,
  transitionTypeConfig,
  index,
  className,
}: GallerySlideInterface) => (
  <Slide
    className={className}
    index={index}
    classNameHidden={
      transitionType === 'fadeIn' || transitionTypeConfig === 'fadeIn'
        ? '!opacity-0 !transition-opacity !duration-[750ms] !absolute'
        : undefined
    }
    classNameVisible={
      transitionType === 'fadeIn' || transitionTypeConfig === 'fadeIn'
        ? '!opacity-100 !transition-opacity !duration-[750ms] !absolute'
        : undefined
    }
  >
    {children}
  </Slide>
)

export default connect(
  ({ projectConfig: { gallerySettings } }: RootStateTypeExtra) => ({
    transitionTypeConfig: gallerySettings?.slideShow?.type,
  })
)(GallerySlide)

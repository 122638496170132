import { CarouselProvider } from 'pure-react-carousel'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { setBrochure } from '@store/actionSlices/brochure'
import type { RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FadeContainer from '@components/fade-container'
import GalleryHandler from '@components/gallery-handler'
import SlideTrigger from '@components/gallery-handler/slide-trigger'
import IdleTimeHandler from '@components/idle-time-handler'

import {
  BrochureData,
  GalleryData,
  selectFromResult,
  useGetBrochureQuery,
} from '@api/brochure'

import GallerySkeleton from './gallery-skeleton'

export interface BrochureProps {
  session: SessionMap | undefined
  projectName: string
  brochure: Array<BrochureData>
}

const Brochure = ({ session, projectName, brochure }: BrochureProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const [toggleControls, setToggleControls] = React.useState(true)
  const [activeLabel, setActiveLabel] = React.useState(0)
  const [isSessionConnected, setIsConnected] = React.useState(false)
  const [transitionTime, setTransitionTime] = React.useState(1000)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const brochurePayload = useGetBrochureQuery(
    { projectName },
    { selectFromResult }
  )

  const handleGalleryHandlerClick = () => {
    setToggleControls((state) => !state)
  }

  React.useEffect(() => {
    const { brochureData } = brochurePayload
    if (brochure.length === 0 && brochureData.length > 0) {
      dispatch(setBrochure(brochureData))
    }
  }, [brochurePayload, brochure])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        brochure: {
          galleryControl: {
            activeTabIndex: activeTabIndexFirebase,
            transitionTime: transitionTimeFirebase,
            isPlaying: isPlayingFirebase,
          },
        },
      } = session

      setIsConnected(connected)
      setActiveLabel(activeTabIndexFirebase)
      setTransitionTime(transitionTimeFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  const carouselData = React.useMemo(
    () =>
      brochure.length > 0
        ? {
            slides: brochure[activeLabel].Images.map((brochureContent) => ({
              id: brochureContent.id,
              src: brochureContent.imageSource,
            })),
            labels: brochure[activeLabel].Images.map(
              (res: GalleryData, index) => ({
                label: res.imageName,
                sublabel: `sublabel-${index}`,
                src: res.imageSource,
                type: 'legacy',
                noSpliceUrl: false,
                gradiant: 0,
              })
            ),
          }
        : { slides: [], labels: [] },
    [brochure, activeLabel]
  )

  return (
    <Container>
      <DataHandler
        payload={{
          ...brochurePayload,
          data: brochure,
          apiData: brochurePayload.brochureData,
        }}
        skeletonFrame={<GallerySkeleton />}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={brochure.length}
          dragEnabled={false}
          orientation="vertical"
          className="h-full ipad-mini:w-1100px"
          interval={transitionTime}
          isPlaying={isSlideShowPlaying}
          infinite
        >
          {!isSessionConnected && brochure.length > 0 && (
            <IdleTimeHandler>
              <FadeContainer className="relative z-10" show={toggleControls}>
                <div className="absolute flex pt-8">
                  <SlideTrigger
                    type="text"
                    items={brochure.map((res: BrochureData) => ({
                      text: res.label,
                    }))}
                    setCurrentSlide={setActiveLabel}
                    currentSlide={activeLabel}
                    dotContainerRef={dotContainerRef}
                    toggleControls={toggleControls}
                  />
                </div>
              </FadeContainer>
            </IdleTimeHandler>
          )}
          <TransitionGroup className="h-full">
            <CSSTransition
              key={activeLabel}
              classNames="transition-fade"
              timeout={500}
            >
              <GalleryHandler
                galleryName="brochure"
                galleryControl={session?.brochure.galleryControl}
                slides={carouselData.slides}
                isConnected={isSessionConnected}
                labels={carouselData.labels}
                dotType="thumbnail"
                onClick={handleGalleryHandlerClick}
                toggleControls={toggleControls}
                showShadows
                toggleBlurredBackground
              />
            </CSSTransition>
          </TransitionGroup>
        </CarouselProvider>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    firestore: { session },
    projectIdentity: { projectName },
    brochure,
  }: RootStateTypeExtra) => ({
    session,
    projectName,
    brochure,
  })
)(Brochure)

import React, { useEffect, useState } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateTypeExtra, SessionMap } from '@store/types'

import { setBackground } from '@utilities/asset-util'
import { hasToken } from '@utilities/helper'
import useMatrixCacheCleaner from '@utilities/matrix-cache-cleaner'

import { ArrowPathSvg } from '@svg/react'

export type ErrorBoundaryProps = FallbackProps & {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  token: TokenPayloadInterface
}

const FALLBACK_IMAGE_URL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL || ''

const ErrorBoundary = ({
  session,
  projectIdentity,
  token,
  error,
  resetErrorBoundary,
}: ErrorBoundaryProps) => {
  const history = useHistory()
  const location = useLocation()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKeys: projectIdentity.sessionId,
  })

  const [isConnected, setIsConnected] = useState(false)

  const [isMatrixCacheCleaned, handleMatrixCacheCleanerTrigger] =
    useMatrixCacheCleaner({
      projectName: projectIdentity.projectName,
      email: token.email,
    })

  const resetClearCacheTriggerKeyFromFirebaseAndReload = async () => {
    if (session) {
      await firebaseControlQuery.update({
        [`clearCacheTriggerKey`]: '',
        [`clearCacheKeyState`]: 'cleared',
      })
    }
    window.location.reload()
  }

  const handleRefreshClick = () => {
    handleMatrixCacheCleanerTrigger(true)
  }

  useEffect(() => {
    if (isMatrixCacheCleaned) {
      resetErrorBoundary()
      history.push(`/${projectIdentity.projectName}/vision`)
      resetClearCacheTriggerKeyFromFirebaseAndReload()
    }
  }, [isMatrixCacheCleaned])

  useEffect(() => {
    if (hasToken(token) && session && error) {
      const { clearCacheTriggerKey: firebaseClearCacheTriggerKey } = session
      handleMatrixCacheCleanerTrigger(firebaseClearCacheTriggerKey !== '')
    }
  }, [session, error])

  useEffect(() => {
    if (session && error) {
      const { activeRoute, connected } = session
      if (connected && projectIdentity.projectName) {
        const paths = location.pathname.split('/')
        if (paths[2] !== activeRoute) {
          resetErrorBoundary()
          history.push(`/${projectIdentity.projectName}/${paths}`)
        }
      }
    }
  }, [session, projectIdentity, error])

  useEffect(() => {
    if (!session) {
      return
    }

    const { connected } = session
    setIsConnected(connected)
  }, [session])

  return (
    <div className="h-screen">
      <div
        className="relative flex h-full flex-col items-center justify-center"
        style={{
          backgroundImage:
            setBackground(FALLBACK_IMAGE_URL)?.backgroundImage ?? '',
        }}
      >
        <ArrowPathSvg className="h-32 w-32 text-neutral-grey" />
        <h3 className="mb-8 mt-4 text-center text-4xl font-bold text-neutral-grey">
          New updates available!
        </h3>
        <p className="mb-8 max-w-[65%] text-center text-3xl font-semibold text-neutral-grey">
          {isConnected ? (
            <>
              {`It looks like this page hasn't loaded correctly, possibly due to
              recent updates. Please navigate to tools and click "Clear Cache"
              button within the Remote.`}
            </>
          ) : (
            <>
              {`
              It looks like this page hasn't loaded correctly, possibly due
              to recent updates. Please go back to the main page or use the menu
              to explore other sections. Alternatively, click below to refresh.
              `}
            </>
          )}
        </p>
        {!isConnected && (
          <button
            type="button"
            className="rounded-lg bg-neutral-grey px-4 py-2 text-xl text-neutral-600"
            onClick={handleRefreshClick}
          >
            Refresh Now
          </button>
        )}
      </div>
    </div>
  )
}

export default connect(
  ({ firestore: { session }, projectIdentity, token }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    token,
  })
)(ErrorBoundary)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { RootStateTypeExtra } from '@store/types'

import { hasToken } from '@utilities/helper'

export type ProtectedRouteProps = {
  authenticationPath: string
  token: TokenPayloadInterface
} & RouteProps

function ProtectedRoutes({
  authenticationPath,
  token,
  ...routeProps
}: ProtectedRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(true)

  useEffect(() => {
    if (!hasToken(token)) {
      setIsAuthenticated(false)
    }
  }, [token])

  if (isAuthenticated) {
    return <Route {...routeProps} />
  }
  return <Redirect to={{ pathname: authenticationPath }} />
}

export default connect(({ token }: RootStateTypeExtra) => ({
  token,
}))(ProtectedRoutes)

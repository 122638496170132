import { Slider, SliderProps } from 'pure-react-carousel'
import React from 'react'
import { connect } from 'react-redux'

import { RootStateTypeExtra } from '@store/types'

export type GallerySliderInterface = SliderProps & {
  transitionType?: string
  transitionTypeConfig?: string
}

const GallerySlider = ({
  children,
  transitionType,
  transitionTypeConfig,
  className,
  onClick,
}: GallerySliderInterface) => (
  <Slider
    classNameAnimation={
      transitionType === 'fadeIn' || transitionTypeConfig === 'fadeIn'
        ? '!transition-opacity !duration-[750ms] !transform-none !relative'
        : undefined
    }
    className={`h-full ${className}`}
    onClick={onClick}
  >
    {children}
  </Slider>
)

export default connect(
  ({ projectConfig: { gallerySettings } }: RootStateTypeExtra) => ({
    transitionTypeConfig: gallerySettings?.slideShow?.type,
  })
)(GallerySlider)

import type { Unsubscribe } from 'firebase/firestore'
import { doc, onSnapshot } from 'firebase/firestore'
import React from 'react'

import firestore from './firebase-db'

const initFirebaseSnapshot = (baseQuery: string, ...queryInit: string[]) => {
  let unsub: Unsubscribe

  const [firebaseData, setFirebaseData] = React.useState<any>(undefined)

  const createSnapShot = () => {
    if (firestore) {
      unsub = onSnapshot(doc(firestore, baseQuery, ...queryInit), (doc) => {
        if (!doc.exists()) {
          setFirebaseData(undefined)
        }
        setFirebaseData(doc.data())
      })
    }
  }

  const validateQuery = (query: string[]) =>
    query.filter((res) => res).length >= 3

  React.useMemo(() => {
    if (baseQuery && validateQuery(queryInit)) {
      createSnapShot()
    }
    return () => unsub && unsub()
  }, [[baseQuery, ...queryInit].toString()])

  return firebaseData
}

export default initFirebaseSnapshot

import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setByFlag } from '@store/actionSlices/houseAndLand'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import {
  setMarkerColour,
  setProjectConfig,
} from '@store/actionSlices/projectConfig'
import {
  InteractivePlanType,
  RootStateTypeExtra,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import {
  selectFromResult as selectFromResultConfig,
  useGetConfigQuery,
} from '@api/config'
import {
  InteractivePlanData,
  MapContent,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import { HouseAndLandActiveKeyType } from '@api/types/house-and-land-type'

import styleUtil from '@utilities/style-util'

import AreaHouseAndLandSkeleton from './area-house-and-land-skeleton'

export interface AreaProps {
  session: SessionMap | undefined
  projectName: string
  interactivePlan: InteractivePlanData
  areaMarkerColour: string
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

const AreaHouseAndLand = ({
  session,
  projectName,
  interactivePlan,
  areaMarkerColour,
}: AreaProps) => {
  const themeData = styleUtil()
  const history = useHistory()
  const dispatch = useDispatch()

  const [isCanvasImageLoaded, setImageLoaded] = useState<boolean>(false)
  const [renderCanvas] = useState<boolean>(true)
  const [theme, setTheme] = useState({
    font: '',
    mainColour: '',
  })
  const [area, setArea] = useState<MapContent>({
    image: '',
    polygons: [],
  })
  const [isConnected, setIsConnected] = useState<boolean>(false)

  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<CanvasRefInterface>()

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName, type: InteractivePlanType.AreaView },
    { selectFromResult }
  )

  const { configInfo, markerColour, isLoaded } = useGetConfigQuery(
    { projectName },
    { selectFromResult: selectFromResultConfig }
  )

  const getLabel = (poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return poly.label
  }

  const setInteractiveAction = (areaViewMap: MapContent) => ({
    ...areaViewMap,
    polygons: areaViewMap.polygons.map((poly) => ({
      ...poly,
      label: getLabel(poly),
      onClick: () => {
        dispatch(
          setByFlag({
            flag: HouseAndLandActiveKeyType.ActivePrecinctId,
            value: poly.groupId,
          })
        )
        if (!isConnected) {
          history.push('precinct')
        }
      },
      markerColour: areaMarkerColour,
    })),
  })

  useEffect(() => {
    if (configInfo && Object.keys(configInfo).length > 0) {
      dispatch(setProjectConfig({ ...configInfo }))
    }
  }, [isLoaded])

  useEffect(() => {
    if (markerColour && Object.keys(markerColour).length > 0) {
      dispatch(setMarkerColour(markerColour))
    }
  }, [isLoaded])

  useEffect(() => {
    if (interactivePlan.areaView?.image) {
      setArea(setInteractiveAction(interactivePlan.areaView))
    }
  }, [interactivePlan, isConnected])

  useEffect(() => {
    const { maps } = interactivePayload

    if (!interactivePlan.areaView?.image && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload, interactivePlan])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      connected,
      areaView: { triggerBuilding },
      houseAndLand: { activePrecinctId },
    } = session

    setIsConnected(connected)

    if (activePrecinctId) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActivePrecinctId,
          value: activePrecinctId,
        })
      )
    }

    if (triggerBuilding && renderCanvas) {
      const polygons: Polygon[] = area?.polygons

      const polyToTrigger = activePrecinctId
        ? polygons?.find(({ groupId }) => groupId === activePrecinctId)?.groupId
        : polygons?.[0]?.groupId
      if (!polyToTrigger) {
        return
      }

      canvasRef?.current?.artificialTrigger(polyToTrigger)
    }
  }, [
    session?.connected,
    session?.areaView.triggerBuilding,
    session?.houseAndLand.activePrecinctId,
  ])

  useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  return (
    <Container>
      <DataHandler
        payload={{
          ...interactivePayload,
          data: interactivePlan?.areaView?.image,
          apiData: interactivePayload.maps?.areaView,
        }}
        skeletonFrame={<AreaHouseAndLandSkeleton />}
      >
        {area.polygons.length > 0 && (
          <>
            <ImageHandler
              key={area.image}
              url={area.image}
              type="new"
              className="background-cover image-blur absolute inset-0 z-2"
              noSpliceUrl
              showFallbackImage={false}
              bgProps={{
                gradiant: 0.5,
              }}
            />

            <div className="absolute inset-0 z-3">
              <div
                key="area-canvas"
                data-testid="area-canvas"
                ref={canvasContainerRef}
                className={`relative flex h-full w-full items-center justify-center overflow-hidden ${
                  isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <CanvasInteractive
                  ref={canvasRef}
                  id="area-canvas"
                  canvasData={area}
                  parentRef={canvasContainerRef}
                  theme={{
                    brandColour:
                      theme?.mainColour || themeData.mainColour || '',
                    font: theme?.font || themeData.font || '',
                  }}
                  adjustCanvasSizeWithContainer
                  setImageLoaded={setImageLoaded}
                />
              </div>
            </div>
          </>
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    firestore: { session },
    projectIdentity: { projectName },
    interactivePlan,
    projectConfig: {
      markerColour: { area: areaMarkerColour },
    },
  }: RootStateTypeExtra) => ({
    session,
    projectName,
    interactivePlan,
    areaMarkerColour,
  })
)(AreaHouseAndLand)

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Channel, RootStateTypeExtra, SessionMap } from '@store/types'

export type Channels = {
  channels: Channel[]
}

interface InterfaceFirebaseMap {
  session: SessionMap | undefined
  lightMap: Channels | undefined
}

const initialState = {
  session: undefined,
  lightMap: undefined,
} as InterfaceFirebaseMap

const firestoreSlice = createSlice({
  name: 'firestoreSlice',
  initialState,
  reducers: {
    setFirebaseSession: (
      state: InterfaceFirebaseMap,
      action: PayloadAction<SessionMap>
    ) => ({
      ...state,
      session: action.payload,
    }),
    setFirebaseLightMap: (
      state: InterfaceFirebaseMap,
      action: PayloadAction<Channels>
    ) => ({
      ...state,
      lightMap: action.payload,
    }),
  },
})

export const { setFirebaseSession, setFirebaseLightMap } =
  firestoreSlice.actions

export const selectFirestore = (state: RootStateTypeExtra) => state.firestore

export default firestoreSlice.reducer

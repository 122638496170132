import React from 'react'
import { connect } from 'react-redux'

import { BuildingInterface } from '@store/actionSlices/building'
import { initialFilter } from '@store/actionSlices/unitFilter'
import { RootStateTypeExtra, UnitFilterInterface } from '@store/types'

import { Level, Unit } from '@api/building'
import {
  EnvisionVRConfigurationInterface,
  SnaploaderConfigurationInterface,
} from '@api/config'
import { MappingBlockCollection } from '@api/interactive-plan'

import {
  countFilterChanges,
  filterUnit as filterUnitUtil,
} from '@utilities/unit-filter-util'

import AddFilterButton from './add-filter-button'
import BackButton from './back-button'
import LevelHead from './level-head'
import NoUnitCard from './no-unit-card'
import UnitCard from './unit-card'

interface UnitsSidePanelProps {
  filterPopupToggle: boolean
  setFilterPopupToggle: (arg: boolean) => void
  building: BuildingInterface
  blocks: MappingBlockCollection
  unitFilter: UnitFilterInterface
  hasThreeDViewer?: boolean
  snaploaderConfiguration: SnaploaderConfigurationInterface
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  showPrice: boolean
  handleLightHandlerState: () => void
  availableStatusLabel: string
}

const UnitsSidePanel = ({
  filterPopupToggle,
  setFilterPopupToggle,
  building,
  blocks,
  unitFilter,
  hasThreeDViewer,
  snaploaderConfiguration,
  envisionVRConfiguration,
  showPrice,
  handleLightHandlerState,
  availableStatusLabel,
}: UnitsSidePanelProps) => {
  const unitSidePanelContainerRef = React.useRef<HTMLDivElement>(null)

  const blockKeys: Array<string> =
    building?.blockOrders.length >= 1
      ? building?.blockOrders
      : Object.keys(blocks) || []

  const [activeLevel, setActiveLevel] = React.useState<Level>({
    level: '',
    data: [],
    height: 0,
  })
  const [filteredCount, setFilteredCount] = React.useState(0)

  const getActiveLevel = () => {
    const [foundLevel] = building.levels.filter(
      (res) => res.level === building.activeLevel
    )
    if (foundLevel) {
      const { level, data, height } = foundLevel
      setActiveLevel({
        level,
        data: building.activeBlock
          ? data.filter((res) => res.blockId === building.activeBlock)
          : data,
        height,
      })
    }
  }

  const getFilteredData = (): Array<Level> => {
    const levelList: Level[] = [...building.levels]

    const { apply: filterApplied, anyLevel } = unitFilter
    const { activeLevel: filteredLevel, activeBlock: filteredBlock } = building

    let filterLevelList = levelList
      .map((unit) => ({
        ...unit,
        data:
          blockKeys.length > 1
            ? unit.data.filter((item) => item.blockId === filteredBlock)
            : unit.data,
      }))
      .filter((unit) => unit.data.length)

    if (!filterApplied) return filterLevelList

    if (!anyLevel) {
      filterLevelList = filterLevelList.filter(
        (item: Level) => item.level === filteredLevel
      )
    }

    const filteredLevels: Level[] = []

    filterLevelList.forEach((item: Level) => {
      let myUnits = item.data

      if (blockKeys.length > 1 && filteredBlock) {
        myUnits = myUnits.filter((u: Unit) => u.blockId === filteredBlock)
      }

      myUnits = myUnits.filter((u: Unit) =>
        filterUnitUtil(u, unitFilter, showPrice, availableStatusLabel)
      )

      if (myUnits.length > 0) {
        filteredLevels.push({ ...item, data: myUnits })
      }
    })

    return filteredLevels
  }

  React.useEffect(() => {
    if (building.activeLevel !== activeLevel.level) {
      getActiveLevel()
    }
  }, [building, unitFilter])

  React.useEffect(() => {
    if (unitFilter.apply) {
      setFilteredCount(countFilterChanges(initialFilter, unitFilter))
    } else {
      setFilteredCount(0)
    }
  }, [unitFilter])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const activeLevelElement = document.querySelector(
        '.active-level'
      ) as HTMLDivElement

      if (
        activeLevelElement &&
        unitSidePanelContainerRef?.current &&
        activeLevel.level !== building.activeLevel
      ) {
        const offsetTop =
          activeLevelElement.offsetTop -
          unitSidePanelContainerRef.current.offsetTop

        unitSidePanelContainerRef.current.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [unitFilter.apply])

  return (
    <>
      <div className="h-full w-full bg-[#EEEEEE]">
        <div className="flex justify-between bg-white p-4">
          <BackButton
            building={building}
            snaploaderConfiguration={snaploaderConfiguration}
            hasThreeDViewer={hasThreeDViewer}
            envisionVRConfiguration={envisionVRConfiguration}
            handleLightHandlerState={handleLightHandlerState}
          />
          <AddFilterButton
            unitCount={filteredCount}
            popupState={filterPopupToggle}
            togglePopup={setFilterPopupToggle}
          />
        </div>
        <div
          ref={unitSidePanelContainerRef}
          className="custom-scrollbar h-unit-sidepanel-container overflow-y-scroll px-4"
        >
          {getFilteredData().length > 0 ? (
            getFilteredData().map((level: Level) => (
              <div
                key={level.level}
                className={`${
                  level.level === activeLevel.level ? 'active-level' : ''
                }`}
              >
                <LevelHead
                  building={building}
                  level={level.level}
                  active={level.level === activeLevel.level}
                />
                {level.data.length > 0 ? (
                  level.data.map((unit: Unit) => (
                    <UnitCard
                      key={unit.id}
                      unit={unit}
                      active={building.activeUnit === unit.name}
                    />
                  ))
                ) : (
                  <NoUnitCard />
                )}
              </div>
            ))
          ) : (
            <NoUnitCard />
          )}
        </div>
      </div>
    </>
  )
}

export default connect(
  ({
    building,
    unitFilter,
    projectConfig: {
      threeDViewerUrl,
      snaploaderConfiguration,
      envisionVRConfiguration,
      showPrice,
      statusLabels,
    },
    interactivePlan: { blocks },
  }: RootStateTypeExtra) => ({
    building,
    unitFilter,
    hasThreeDViewer: threeDViewerUrl !== '',
    snaploaderConfiguration,
    envisionVRConfiguration,
    showPrice,
    blocks,
    availableStatusLabel: statusLabels?.available,
  })
)(UnitsSidePanel)

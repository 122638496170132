import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FirebaseControlQuery, {
  getCurrentSessionKey,
} from '@src/firebase-util/firebase-control-query'

import {
  AppConfigInterface,
  removeAppConfig,
} from '@store/actionSlices/appConfig'
import {
  removeProjectIdentity,
  setProjectIdentity,
} from '@store/actionSlices/projectIdentity'
import { removeToken } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import { FULFILLED_STATUS } from '@components/data-handler/data-handler'

import { EnvisionVRConfigurationInterface } from '@api/config'
import {
  ProjectListDetails,
  selectFromResult,
  useGetProjectListQuery,
} from '@api/project-list'

import { getFirebaseLocalHost, hasEnvisionVR } from '@utilities/helper'
import ResetUtil from '@utilities/reset-util'
import Throttle from '@utilities/throttle'

import { ArrowRefreshSvg, ChevronSvg, ChevronUpDownSvg } from '@svg/react'

import { createSessionId } from '@adUtilities/generate-id-util'

import EnvisionAssetHandler from './envision-asset-handler'
import LightModelHandler from './light-model-handler'
import LocalAssetHandler from './local-asset-handler'
import MatrixCacheHandler from './matrix-cache-handler'
import OfflineModeHandler from './offline-mode-handler'
import {
  ProjectListCollapseSkeleton,
  ProjectListExpandSkeleton,
} from './project-list-skeleton'
import RoomHandler from './room-handler'
import SessionHandler from './session-handler'

interface SettingsProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  appConfig: AppConfigInterface
  authenticatedEmail: string
  hasQuerySearch: boolean
  envisionVRConfiguration: EnvisionVRConfigurationInterface
}

const PAGE_SIZE = 5

const Settings = ({
  projectIdentity,
  session,
  appConfig,
  hasQuerySearch,
  authenticatedEmail,
  envisionVRConfiguration,
}: SettingsProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKeys: projectIdentity.sessionId,
  })

  const [isProjectSwitchOpen, setProjectSwitchDropDown] = React.useState(false)

  const [page, setPage] = React.useState(1)

  const [projectQuery, setProjectQuery] = React.useState('')

  const query = Throttle(projectQuery, 500)

  const projectListPayload = useGetProjectListQuery(
    {
      email: authenticatedEmail,
      page,
      query,
    },
    { selectFromResult }
  )

  const resetUtil = ResetUtil()

  const clearSessionId = () => {
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        sessionId: projectIdentity.sessionId.filter(
          (res) => res.project !== projectIdentity.projectName
        ),
      })
    )
  }

  const deleteSessionFireStore = async () => {
    const { projectName } = projectIdentity
    const currentSessionKey = getCurrentSessionKey({
      sessionKeys: projectIdentity.sessionId,
      projectName: projectIdentity.projectName,
    })

    if (currentSessionKey && projectName) {
      await firebaseControlQuery.updateConnection(false)
      await firebaseControlQuery.deleteSession()
      clearSessionId()
    }
  }

  const createSessionFireStore = async ({
    sessionId,
    projectName,
  }: ProjectIdentity) => {
    const currentSessionKey = getCurrentSessionKey({
      sessionKeys: sessionId,
      projectName,
    })
    if (currentSessionKey && projectName) {
      const response = await firebaseControlQuery.createSession({
        sessionId: currentSessionKey,
        projectName,
      })
      if (!response) {
        alert('Session creation experienced a conflict. Please try again.')
        clearSessionId()
      }
    }
  }

  const projectSwitch = ({ titleCanonical, id, title }: ProjectListDetails) => {
    setProjectSwitchDropDown(false)
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        projectLabel: title,
        projectName: titleCanonical,
        projectId: id,
      })
    )
    resetUtil.resetLocalStorage()
    history.replace({ pathname: `/${titleCanonical}/vision` })
    window.location.reload()
  }

  const createSession = () => {
    const key = getCurrentSessionKey({
      sessionKeys: projectIdentity.sessionId,
      projectName: projectIdentity.projectName,
    })
    if (!key) {
      const updatedProjectIdentity = {
        ...projectIdentity,
        sessionId: [
          ...projectIdentity.sessionId,
          {
            project: projectIdentity.projectName,
            key: createSessionId(),
          },
        ],
      }
      dispatch(setProjectIdentity(updatedProjectIdentity))
      createSessionFireStore(updatedProjectIdentity)
    }
  }

  const logout = () => {
    const firebaseLocalhost = getFirebaseLocalHost()
    dispatch(removeToken())
    dispatch(removeProjectIdentity())
    deleteSessionFireStore()
    dispatch(removeAppConfig())
    resetUtil.resetLocalStorage()
    if (firebaseLocalhost.firebasePort) window.location.reload()
  }

  const getPageCount = React.useMemo(
    () => Math.ceil((projectListPayload.pagination?.total || 0) / PAGE_SIZE),
    [projectListPayload]
  )

  const getProjectPage = (status: 'prev' | 'next') => {
    const maxPages = getPageCount
    setPage((state: number) => {
      if (status === 'prev') {
        return state > 1 ? state - 1 : 1
      }
      if (status === 'next' && state < maxPages) {
        return state + 1
      }
      return state
    })
  }

  const isLoading = projectListPayload.status !== FULFILLED_STATUS

  return (
    <Container>
      <div className="z-30 h-full w-full bg-white">
        <div className="flex h-2/4 w-full bg-slate-50">
          <div className="px-15 m-auto h-auto w-1/3 items-center">
            <div className="flex justify-between">
              <div className="flex flex-wrap gap-2">
                <b>Session Settings</b>
                <RoomHandler room={appConfig.room} />
              </div>
              <button
                onClick={() => logout()}
                type="button"
                className="text-red-700 underline underline-offset-2"
              >
                Logout and exit
              </button>
            </div>
            <div className="pt-5">
              <div className="relative z-10 pt-2">
                <div
                  className={`${
                    !isLoading ? 'bg-white' : 'bg-slate-100'
                  } absolute left-0 top-18 w-full
                  overflow-hidden drop-shadow-md transition-opacity duration-700 ease-in-out
                  ${
                    isProjectSwitchOpen
                      ? 'visible opacity-100'
                      : 'pointer-events-none invisible opacity-0'
                  }`}
                >
                  {hasQuerySearch && (
                    <div className="flex w-full items-center justify-center px-5 py-4">
                      <span className="mr-2">Search: </span>
                      <input
                        className="w-full rounded border-gray-400 focus:border-gray-400 focus:ring-0"
                        type="text"
                        value={projectQuery}
                        onChange={(e) => setProjectQuery(e.target.value)}
                      />
                    </div>
                  )}

                  {isLoading ? (
                    <ProjectListExpandSkeleton
                      length={projectListPayload.list.length}
                    />
                  ) : (
                    projectListPayload.list.map((prj: ProjectListDetails) => (
                      <button
                        key={prj.id}
                        type="button"
                        className="flex w-full items-center border-b px-5 py-5 text-left"
                        onClick={() => projectSwitch(prj)}
                        disabled={isLoading}
                      >
                        <span className={`${isLoading ? 'text-gray-500' : ''}`}>
                          {prj?.title}
                        </span>
                        <ArrowRefreshSvg
                          className={`ml-auto ${
                            isLoading ? 'text-gray-500' : 'text-sky-500'
                          }`}
                        />
                        <span
                          className={`pl-2 ${
                            isLoading ? 'text-gray-500' : 'text-sky-500'
                          }`}
                        >
                          Switch
                        </span>
                      </button>
                    ))
                  )}

                  {projectListPayload.list.length === 0 && (
                    <div className="w-full p-4 text-center">No Results</div>
                  )}
                  <div className="flex w-full items-center justify-between gap-4 border-b bg-white px-4 py-1 text-left">
                    <div>
                      Page{' '}
                      <span
                        className={`bold ${
                          isLoading ? 'text-gray-500' : 'text-sky-500'
                        }`}
                      >
                        {page || ''}
                      </span>{' '}
                      of{' '}
                      <span
                        className={`bold ${
                          isLoading ? 'text-gray-500' : 'text-sky-500'
                        }`}
                      >
                        {getPageCount}
                      </span>
                    </div>

                    {isLoading && (
                      <p className="inline-flex items-center gap-2 text-gray-500">
                        <ArrowRefreshSvg className="animate-spin text-sky-500" />{' '}
                        Loading...
                      </p>
                    )}

                    <div className="flex items-center">
                      <button
                        onClick={() => getProjectPage('prev')}
                        disabled={isLoading || page === 1}
                        type="button"
                      >
                        <ChevronSvg
                          className={`${
                            isLoading ? 'text-gray-500' : 'text-sky-500'
                          }`}
                          width="35"
                          height="35"
                          size="xs"
                        />
                      </button>
                      <span
                        className={`${
                          isLoading ? 'text-gray-500' : 'text-sky-500'
                        }`}
                      >
                        |
                      </span>
                      <button
                        disabled={isLoading || page === getPageCount}
                        onClick={() => getProjectPage('next')}
                        type="button"
                      >
                        <ChevronSvg
                          className={`${
                            isLoading ? 'text-gray-500' : 'text-sky-500'
                          }`}
                          width="35"
                          height="35"
                          size="xs"
                          rotate="right"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {isLoading && projectListPayload.list.length === 0 ? (
                  <ProjectListCollapseSkeleton />
                ) : (
                  <button
                    disabled={isLoading}
                    onClick={() =>
                      setProjectSwitchDropDown(!isProjectSwitchOpen)
                    }
                    type="button"
                    className="flex w-full items-center rounded border-2 border-gray-300 p-3 px-5 text-left"
                  >
                    <span>{projectIdentity?.projectLabel}</span>
                    <ChevronUpDownSvg
                      className="ml-auto"
                      width="35"
                      height="35"
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="pt-6">
              <b>Use a remote to control Showcase</b>
              <div className="pt-4">
                <SessionHandler
                  createSession={() => createSession()}
                  removeSession={() => deleteSessionFireStore()}
                  {...{ projectIdentity, connected: session?.connected }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-2/4 w-full">
          <div className="px-15 m-auto h-auto w-1/3 items-center">
            <div>
              <b>Other tools & settings</b>
            </div>
            <div className="pt-5">
              <div className="grid auto-cols-fr grid-flow-col grid-rows-2 gap-4 pt-2">
                <LocalAssetHandler port={appConfig.assetPort} />
                <LightModelHandler lightMap={projectIdentity.lightMap} />
                <MatrixCacheHandler
                  projectName={projectIdentity.projectName}
                  email={authenticatedEmail}
                />
                <OfflineModeHandler
                  firebasePort={appConfig.firebasePort}
                  offlineMode={appConfig.offlineMode}
                  firebaseIp={appConfig.firebaseIp}
                />
              </div>
              {hasEnvisionVR(envisionVRConfiguration) && (
                <div className="mt-3.5">
                  <EnvisionAssetHandler
                    assetConfig={appConfig.envisionAssetConfig}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore: { session },
    appConfig,
    token,
    appConfig: { searchQuery },
    projectConfig: { envisionVRConfiguration },
  }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
    appConfig,
    authenticatedEmail: token.email,
    hasQuerySearch: searchQuery,
    envisionVRConfiguration,
  })
)(Settings)

/* eslint-disable react/prop-types */
import React from 'react'
import { Range, getTrackBackground } from 'react-range'

interface VolumeBarProps {
  volume: number
  setVolume: (newVolume: number) => void
  brandColour: string
}

const VolumeBar = ({ volume, setVolume, brandColour }: VolumeBarProps) => {
  const [sliderRangeValue, setSliderRangeValue] = React.useState<number[]>([
    volume * 100,
  ])

  const handleOnChange = (newVolume: number[]) => {
    setSliderRangeValue(newVolume)

    const adjustedVolumes = newVolume.map(
      (volume) => Math.round(volume + 0.1) / 100
    )
    setVolume(adjustedVolumes[0] ?? 0)
  }

  const handleOnFinalChange = (newVolume: number[]) => {
    handleOnChange(newVolume)
  }

  return (
    <div className="group relative w-full">
      <Range
        min={0}
        max={100}
        step={0.1}
        values={sliderRangeValue}
        onChange={(value) => handleOnChange(value)}
        onFinalChange={(value) => handleOnFinalChange(value)}
        renderTrack={({ props, children }) => (
          <div
            role="slider"
            tabIndex={0}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={sliderRangeValue[0]}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              className="h-2 w-full rounded"
              style={{
                background: getTrackBackground({
                  values: sliderRangeValue,
                  colors: [brandColour, '#71717a'],
                  min: 0,
                  max: 100,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            key={props.key}
            className="h-4 w-4 rounded-full opacity-0 group-hover:opacity-100"
            style={{
              backgroundColor: brandColour,
            }}
          ></div>
        )}
      />
    </div>
  )
}

export default VolumeBar

import React from 'react'
import { connect } from 'react-redux'

import { RootStateTypeExtra } from '@src/store/types'

import { StatusLabels } from '@api/config'
import {
  ConfigurationInterface,
  LotInterface,
  PackageInterface,
  PrecinctListItemInterface,
} from '@api/types/house-and-land-type'

import { formatPrice } from '@utilities/helper'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  ChevronSvg,
  CompassRegularSvg,
  MinusSvg,
  PlusSvg,
  PowderSvg,
  StudySvg,
} from '@svg/react'

import { formatAreaNumber } from '@adUtilities/helper'

const AREA_UNIT = 'sqm'
const FRONTAGE_UNIT = 'm'

interface ComponentPropsInterface {
  lot: LotInterface
  packageInfo: PackageInterface | undefined
  precinctList: Array<PrecinctListItemInterface>
  totalPrice: number
  connected: boolean
  activePrecinctId: string
  activeStageId: string
  showPrice: boolean
  hideSoldPrice: boolean
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
  handlePackageInfoModal: () => void
  statusLabels: StatusLabels
}

const PackageInfoCard = ({
  lot,
  packageInfo,
  precinctList,
  totalPrice,
  connected,
  activePrecinctId,
  activeStageId,
  showPrice,
  hideSoldPrice,
  hideUnitInfoName,
  hideInfoPanelAspect,
  handlePackageInfoModal,
  statusLabels,
}: ComponentPropsInterface) => {
  const [showDetail, setShowDetailState] = React.useState(false)

  const configuration: ConfigurationInterface = React.useMemo(() => {
    const output = {
      bed: 0,
      study: 0,
      bath: 0,
      powderRoom: 0,
      car: 0,
    }

    if (!packageInfo) {
      return output
    }

    const { configuration: packageConfig } = packageInfo

    return {
      bed: Number(packageConfig?.bed || 0),
      study: Number(packageConfig?.study || 0),
      bath: Number(packageConfig?.bath || 0),
      powderRoom: Number(packageConfig?.powderRoom || 0),
      car: Number(packageConfig?.car || 0),
    }
  }, [packageInfo])

  const canWeShowPrice: boolean = React.useMemo(
    () =>
      showPrice && !(lot.status !== statusLabels?.available && hideSoldPrice),
    [showPrice, lot, hideSoldPrice]
  )

  return (
    <div
      className={`rounded-3xl bg-[#20202080] px-6 pt-6 transition-all delay-150 duration-300 ${
        showDetail ? 'pb-2' : 'pb-6'
      }`}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between">
          <div>
            {activePrecinctId && precinctList.length > 1 && (
              <div className="text-lg font-semibold uppercase leading-[21.5px] text-white">
                {activePrecinctId}
              </div>
            )}
            {!hideUnitInfoName && (
              <div className="text-[29px] font-bold leading-[35px] text-white">
                {packageInfo?.name || lot.name}
              </div>
            )}
            {totalPrice > 0 && canWeShowPrice && (
              <div className="text-[25.5px] font-medium leading-[30.5px] text-white">
                {formatPrice(totalPrice)}
              </div>
            )}
          </div>
          {!connected && (
            <button
              type="button"
              onClick={() => setShowDetailState(!showDetail)}
              className={`inline-flex h-8 w-8 transform items-center justify-center rounded-full bg-white/30 text-white transition-transform duration-700 ease-in-out ${
                showDetail ? 'rotate-180' : ''
              }`}
            >
              {!showDetail ? (
                <MinusSvg className="h-5 w-5" stroke={2} />
              ) : (
                <PlusSvg className="h-6 w-6" stroke={2} />
              )}
            </button>
          )}
        </div>

        <div className="flex justify-between space-x-6 rounded-2xl bg-black/30 p-5 text-white empty:hidden">
          {configuration.bed > 0 && (
            <div
              className="inline-flex items-center gap-1 text-white"
              title="Bed"
            >
              <BedSvg className="h-[18px] w-[18px]" />
              <span className="text-lg font-medium leading-[21.5px]">
                {configuration.bed}
              </span>
            </div>
          )}
          {configuration.study > 0 && (
            <div
              className="inline-flex items-center gap-1 text-white"
              title="Study"
            >
              <StudySvg className="h-[18px] w-[18px]" />
              <span className="text-lg font-medium leading-[21.5px]">
                {configuration.study}
              </span>
            </div>
          )}
          {configuration.bath > 0 && (
            <div
              className="inline-flex items-center gap-1 text-white"
              title="Bath"
            >
              <BathSvg className="h-[18px] w-[18px]" />
              <span className="text-lg font-medium leading-[21.5px]">
                {configuration.bath}
              </span>
            </div>
          )}
          {configuration.powderRoom > 0 && (
            <div
              className="inline-flex items-center gap-1 text-white"
              title="Powder"
            >
              <PowderSvg className="h-[18px] w-[18px]" />
              <span className="text-lg font-medium leading-[21.5px]">
                {configuration.powderRoom}
              </span>
            </div>
          )}
          {configuration.car > 0 && (
            <div
              className="inline-flex items-center gap-1 text-white"
              title="Car"
            >
              <CarSvg className="h-[18px] w-[18px]" />
              <span className="text-lg font-medium leading-[21.5px]">
                {configuration.car}
              </span>
            </div>
          )}
          {lot.aspect && (
            <div
              className="inline-flex items-center gap-1 text-white"
              title="Aspect"
            >
              <CompassRegularSvg
                fill="#ffffff"
                size="m"
                className="h-[18px] w-[18px]"
              />
              <span className="text-lg font-medium leading-[21.5px]">
                {lot.aspect}
              </span>
            </div>
          )}
        </div>
        <div
          className={`grid grid-flow-col grid-rows-3 gap-x-6 gap-y-2 overflow-hidden text-white transition-all delay-150 duration-300 ${
            showDetail ? 'h-0' : 'h-24'
          }`}
        >
          <div className="text-xl leading-6">
            Lot: <span className="font-bold">{lot.name}</span>
          </div>
          {lot.area && (
            <div className="text-xl leading-6">
              Lot Area:{' '}
              <span className="font-bold">{`${formatAreaNumber(
                lot.area
              )} ${AREA_UNIT}`}</span>
            </div>
          )}
          {!hideInfoPanelAspect && lot.aspect && (
            <div className="text-xl leading-6">
              Aspect: <span className="font-bold">{lot.aspect || 'N/A'}</span>
            </div>
          )}
          <div className="text-xl leading-6">
            Stage: <span className="font-bold">{activeStageId || ''}</span>
          </div>
          <div className="text-xl leading-6">
            Lot Frontage:{' '}
            <span className="font-bold">{`${formatAreaNumber(
              lot.frontage
            )} ${FRONTAGE_UNIT}`}</span>
          </div>
          {configuration.study > 0 && (
            <div className="text-xl leading-6">
              Study: <span className="font-bold">{configuration.study}</span>
            </div>
          )}
          <div className="hidden">
            <button
              type="button"
              className="inline-flex cursor-pointer items-center underline underline-offset-2"
              onClick={handlePackageInfoModal}
            >
              <span>Show inclusions</span>
              <ChevronSvg rotate="right" className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectConfig: {
      showPrice,
      hideSoldPrice,
      hideUnitInfoName,
      hideInfoPanelAspect,
      statusLabels,
    },
    houseAndLand: { activePrecinctId, activeStageId, precinctList },
  }: RootStateTypeExtra) => ({
    showPrice,
    hideSoldPrice,
    hideUnitInfoName,
    hideInfoPanelAspect,
    activePrecinctId,
    activeStageId,
    precinctList,
    statusLabels,
  })
)(PackageInfoCard)

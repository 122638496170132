import React from 'react'
import { connect } from 'react-redux'

import { RootStateTypeExtra } from '@src/store/types'

import { Unit } from '@api/building'
import { StatusLabels } from '@api/config'

import { formatPrice } from '@utilities/helper'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  FloorplanSvg,
  PowderSvg,
  StudySvg,
} from '@svg/react'

import { formatAreaNumber } from '@adUtilities/helper'

interface ComponentPropsInterface {
  unit: Unit
  showPrice: boolean
  hideSoldPrice: boolean
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
  statusLabels: StatusLabels
}

const UnitInfoCard = ({
  unit,
  showPrice,
  hideSoldPrice,
  hideUnitInfoName,
  hideInfoPanelAspect,
  statusLabels,
}: ComponentPropsInterface) => {
  const AREA_UNIT = 'sqm'

  const canWeShowPrice =
    showPrice &&
    !(unit?.metas.status !== statusLabels?.available && hideSoldPrice)

  return (
    <>
      {!hideUnitInfoName && (
        <div className="mb-1 text-3xl font-bold text-white drop-shadow-70">
          {unit.name}
        </div>
      )}
      {Number(unit?.metas?.price) > 0 && canWeShowPrice && (
        <div className="mb-3 text-2xl font-bold text-white drop-shadow-70">
          {formatPrice(unit.metas.price)}
        </div>
      )}
      <div className="mb-5 flex justify-between rounded-2xl bg-white px-6 py-4 text-base">
        {Number(unit.metas?.configurations?.bed) > 0 && (
          <div className="flex items-center gap-1.5" title="Bed">
            <BedSvg className="w-4.25 h-4.5" width="18" height="16" />
            <span>{unit.metas?.configurations?.bed}</span>
          </div>
        )}
        {Number(unit.metas?.configurations?.study) > 0 && (
          <div className="flex items-center gap-1.5" title="Study">
            <StudySvg className="w-4.25 h-4.5" width="18" height="16" />
            {unit.metas?.configurations?.study}
          </div>
        )}
        {Number(unit.metas?.configurations?.bath) > 0 && (
          <div className="flex items-center gap-1.5" title="Bath">
            <BathSvg className="w-4.25 h-4.5" width="18" height="16" />
            <span>{unit.metas?.configurations?.bath}</span>
          </div>
        )}
        {Number(unit.metas?.configurations?.powderRoom) > 0 && (
          <div className="flex items-center gap-1.5" title="Powder">
            <PowderSvg className="w-4.25 h-4.5" width="18" height="16" />
            <span>{unit.metas?.configurations?.powderRoom}</span>
          </div>
        )}
        {Number(unit.metas?.configurations?.car) > 0 && (
          <div className="flex items-center gap-1.5" title="Car">
            <CarSvg className="w-4.25 h-4.5" width="18" height="16" />
            <span>{unit.metas?.configurations?.car}</span>
          </div>
        )}
        <div className="flex items-center gap-1.5" title="Total Area">
          <FloorplanSvg className="w-4.25 h-4.5" width="18" height="16" />
          <span>{`${formatAreaNumber(
            unit.metas.totalArea
          )} ${AREA_UNIT}`}</span>
        </div>
      </div>
      <div className="flex text-xl text-white  drop-shadow-70">
        <div>
          <div className="mb-2 font-bold">
            Level: <span>{unit.metas.level}</span>
          </div>
          <div className="mb-2 font-bold">
            Internal Size:{' '}
            <span>{`${formatAreaNumber(
              unit.metas.internalArea
            )} ${AREA_UNIT}`}</span>
          </div>
        </div>
        <div className="ml-6">
          {!hideInfoPanelAspect && unit.metas.aspect && (
            <div className="mb-2 font-bold">
              Aspect: <span>{unit.metas.aspect}</span>
            </div>
          )}
          <div className="mb-2 font-bold">
            External Size:{' '}
            <span>{`${formatAreaNumber(
              unit.metas.externalArea
            )} ${AREA_UNIT}`}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(
  ({
    projectConfig: {
      showPrice,
      hideSoldPrice,
      hideUnitInfoName,
      hideInfoPanelAspect,
      statusLabels,
    },
  }: RootStateTypeExtra) => ({
    showPrice,
    hideSoldPrice,
    hideUnitInfoName,
    hideInfoPanelAspect,
    statusLabels,
  })
)(UnitInfoCard)

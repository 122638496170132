import React from 'react'
import QRCode from 'react-qr-code'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import FirebaseControlQuery from '@src/firebase-util/firebase-control-query'

import { setShortlistPropertyHash } from '@store/actionSlices/appConfig'
import { ConfigInterface } from '@store/actionSlices/projectConfig'
import {
  ShortlistState,
  removeShortlistAgent,
  setShortlistAgent,
  setShortlistDocuments,
} from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import DropDown from '@components/dropdown'

import {
  Agent,
  NewAgentPayload,
  selectFromAgentsResult,
  useCreatePartnerAgentMutation,
  useGetAllAgentsQuery,
  useLazyGetAgentByRefQuery,
} from '@api/agent'
import {
  ShortlistDocument,
  selectFromShortlistDocumentsResult,
  useGetShortlistDocumentsQuery,
} from '@api/shortlist'

import { notifyError } from '@utilities/adgroup-utilities/notifier'
import { ProjectType } from '@utilities/adgroup-utilities/types/common'

import {
  CheckmarkCircleSvg,
  CloseSvg,
  FileSvg,
  LoadingSvg,
  PlusCircleSvg,
  PlusSvg,
} from '@svg/react'

import { generateId } from '@adUtilities/generate-id-util'

import AddAgentForm from './add-agent-form'
import EmptyState from './empty-state'
import LotsList from './lots-list'
import ShortlistSkeleton from './shortlist-skeleton'
import UnitsList from './units-list'
import useGetQRCodeData from './useGetQRCodeData'
import useGetShortlistData from './useGetShortlistData'

export interface ShortlistedProps {
  shortlist: ShortlistState
  projectIdentity: ProjectIdentity
  projectName: string
  projectConfig: ConfigInterface
  session: SessionMap | undefined
  shortlistPropertyHash: string
}

const buyersPortalUrl = process.env.REACT_APP_BUYERS_PORTAL_MICROSERVICE_URL

const Shortlisted = ({
  shortlist,
  projectIdentity,
  projectName,
  projectConfig,
  session,
  shortlistPropertyHash,
}: ShortlistedProps) => {
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKeys: projectIdentity.sessionId,
  })

  const propertyType = React.useMemo<ShortlistPropertyType>(
    () =>
      projectConfig.type === ProjectType.HouseAndLand
        ? ShortlistPropertyType.Lot
        : ShortlistPropertyType.Unit,
    []
  )

  const [isConnected, setIsConnected] = React.useState(false)

  const [isFirebaseLoading, setIsFirebaseLoading] =
    React.useState<boolean>(false)

  const [documentsList, setDocumentsList] = React.useState<ShortlistDocument[]>(
    []
  )
  const [selectedDocuments, setSelectedDocuments] = React.useState<string[]>(
    shortlist.documents || []
  )

  const [agentsList, setAgentsList] = React.useState<Agent[]>([])
  const [selectedAgent, setSelectedAgent] = React.useState<Agent | undefined>()

  const { filteredShortlists, queryResult, queryResultData } =
    useGetShortlistData({
      propertyType,
      isConnected,
      projectName,
      sessionShortlists: session?.shortlist?.properties,
      shortlists: shortlist.shortlists,
      availableStatusLabel: projectConfig?.statusLabels?.available,
    })

  const shortlistData = useGetQRCodeData({
    projectName,
    shortlists: filteredShortlists,
    agentId: selectedAgent?.id,
    documentIds: selectedDocuments,
  })

  const [showAddAgentForm, setShowAddAgentForm] = React.useState(false)
  const [addAgentErrors, setAddAgentErrors] = React.useState<string[]>()
  const [createShortlistAgent, { isLoading: isCreatingAgent }] =
    useCreatePartnerAgentMutation()
  const [
    getShortlistAgentByEmailOrId,
    { isFetching: isFetchingShortlistAgentByEmailOrId },
  ] = useLazyGetAgentByRefQuery()

  const handleSelectDocument = (document: ShortlistDocument) => {
    setSelectedDocuments([...selectedDocuments, document.id])
    dispatch(setShortlistDocuments([...selectedDocuments, document.id]))
  }

  const handleUnselectDocument = (documentId: string) => {
    setSelectedDocuments(
      selectedDocuments.filter((item) => item !== documentId)
    )
    dispatch(
      setShortlistDocuments(
        selectedDocuments.filter((item) => item !== documentId)
      )
    )
  }

  const shortlistDocumentsResponse = useGetShortlistDocumentsQuery(
    { projectName },
    { selectFromResult: selectFromShortlistDocumentsResult }
  )

  const shortlistAgentsResponse = useGetAllAgentsQuery(
    { projectName, showcaseEnabled: true, type: 'agent' },
    { selectFromResult: selectFromAgentsResult }
  )

  const firebaseSelectedAgentId = React.useMemo(() => {
    if (session?.connected) {
      return session?.shortlist?.selectedAgentId
    }
    return undefined
  }, [session?.connected, session?.shortlist?.selectedAgentId])

  const clearSelectedAgent = () => {
    setSelectedAgent(undefined)
    dispatch(removeShortlistAgent())
  }

  const handleAgentChange = async (
    agentId: string,
    fetchWhenUnavailable?: boolean
  ) => {
    if (agentId === 'new') {
      setShowAddAgentForm(true)
      return
    }
    setShowAddAgentForm(false)
    const newSelectedAgent = shortlistAgentsResponse?.data?.find(
      (item) => item.id === agentId
    )

    if (fetchWhenUnavailable && agentId && !newSelectedAgent) {
      try {
        const resp = await getShortlistAgentByEmailOrId({
          projectId: projectName,
          ref: agentId,
        })
        if (resp.data?.data) {
          setSelectedAgent(resp.data.data)
          dispatch(setShortlistAgent({ agentId: resp.data.data.id }))
          return
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (newSelectedAgent) {
      setSelectedAgent(newSelectedAgent)
      dispatch(setShortlistAgent({ agentId: newSelectedAgent.id }))
    } else {
      clearSelectedAgent()
    }
  }

  const handleResetAddAgentForm = () => {
    setAddAgentErrors(undefined)
    setShowAddAgentForm(false)
  }

  const handleAddAgent = async (
    values: Record<keyof NewAgentPayload, string>
  ) => {
    try {
      const existingAgentResponse = await getShortlistAgentByEmailOrId({
        ref: values.email,
        projectId: projectName,
      }).unwrap()
      if (existingAgentResponse?.data) {
        handleResetAddAgentForm()
        setSelectedAgent(existingAgentResponse.data)
        dispatch(setShortlistAgent({ agentId: existingAgentResponse.data.id }))
        return
      }
    } catch (error) {
      console.log(error)
    }
    try {
      const newAgentResponse = await createShortlistAgent({
        ...values,
        projectName,
      }).unwrap()
      if (!newAgentResponse.data) {
        return
      }
      handleResetAddAgentForm()
      setSelectedAgent(newAgentResponse.data)
      dispatch(setShortlistAgent({ agentId: newAgentResponse.data.id }))
    } catch (e) {
      const error = e as { data: { errors: string[]; message: string } }
      if (error?.data?.message) {
        notifyError(error?.data?.message)
      }
      if (error?.data?.errors?.length) {
        setAddAgentErrors(error.data.errors)
      }
    }
  }

  const uploadShortlistToFirebase = async () => {
    setIsFirebaseLoading(true)
    await firebaseControlQuery.updateShortlist({
      key: shortlistPropertyHash,
      payload: shortlistData,
    })
    setIsFirebaseLoading(false)
  }

  React.useEffect(() => {
    const { data: shortlistDocuments, isLoaded } = shortlistDocumentsResponse
    if (
      shortlistDocuments.length > 0 &&
      isLoaded &&
      documentsList.length === 0
    ) {
      setDocumentsList(shortlistDocuments)
    }
  }, [shortlistDocumentsResponse])

  React.useEffect(() => {
    const { data: shortlistAgents, isLoaded } = shortlistAgentsResponse
    if (shortlistAgents.length > 0 && isLoaded && agentsList.length === 0) {
      setAgentsList(shortlistAgents)
    }
  }, [shortlistAgentsResponse])

  React.useEffect(() => {
    if (session) {
      const { connected, shortlist: firebaseShortlist } = session
      setIsConnected(connected)

      if (connected && documentsList && agentsList) {
        setSelectedDocuments(
          documentsList
            .filter((item) =>
              firebaseShortlist.selectedDocumentIds.includes(item.id)
            )
            .map((document) => document.id)
        )
      }
    }
  }, [session, documentsList, agentsList])

  React.useEffect(() => {
    if (shortlist.agent) {
      handleAgentChange(shortlist.agent, true)
    }
  }, [])

  React.useEffect(() => {
    handleAgentChange(firebaseSelectedAgentId || '', true)
  }, [firebaseSelectedAgentId])

  React.useEffect(() => {
    if (shortlistData.shortlist?.length === 0) {
      return
    }

    if (shortlistPropertyHash) {
      uploadShortlistToFirebase()
      return
    }

    dispatch(setShortlistPropertyHash(generateId(6, 'alphanumeric')))
  }, [shortlistData, shortlistPropertyHash])

  const agentOptions = React.useMemo(
    () => [
      ...(!session?.connected
        ? [
            {
              label: (
                <div className="group inline-flex w-full justify-between">
                  <p className="group-hover:underline">Add a new agent</p>
                  <p>+</p>
                </div>
              ),
              value: 'new',
              postFix: <PlusSvg className="h-5 w-5" />,
            },
          ]
        : []),
      { label: 'None', value: '' },
      ...agentsList.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    ],
    [agentsList]
  )

  return (
    <>
      <Container className="flex bg-white transition-size-spacing duration-300 ease-in-out">
        <DataHandler
          payload={{
            ...queryResult,
            data: queryResultData,
          }}
          skeletonFrame={<ShortlistSkeleton />}
        >
          {filteredShortlists.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="flex w-full p-3">
              <div className="flex shrink-0 flex-col rounded-2xl bg-neutral-grey-2 p-11 pb-4">
                {propertyType === ShortlistPropertyType.Lot ? (
                  <LotsList
                    shortlists={filteredShortlists as LotShortlistInterface[]}
                  />
                ) : (
                  <UnitsList
                    shortlistsData={queryResultData}
                    shortlists={filteredShortlists as UnitShortlistInterface[]}
                  />
                )}
              </div>

              <div className="no-scrollbar flex w-full flex-col items-center justify-center overflow-y-auto p-3 short-screen:justify-normal">
                {isFirebaseLoading ? (
                  <div className="mb-10 h-[250px] w-[250px] rounded-lg bg-zinc-400/20">
                    <div className="shimmer-container relative h-full rounded-none opacity-50 shadow-none"></div>
                  </div>
                ) : (
                  <CSSTransition
                    classNames="transition-zoom-out-qr"
                    in={showAddAgentForm}
                    timeout={300}
                  >
                    {showAddAgentForm ? (
                      <button
                        className="mb-12 w-[250px] rounded-md border border-[#DDD] px-5 py-4"
                        type="button"
                        onClick={handleResetAddAgentForm}
                        disabled={
                          isFetchingShortlistAgentByEmailOrId || isCreatingAgent
                        }
                      >
                        <QRCode
                          value={`${buyersPortalUrl}?shortlist-key=${shortlistPropertyHash}`}
                          style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                          }}
                        />
                      </button>
                    ) : (
                      <div className="mb-12 w-[250px] rounded-md border border-[#DDD] px-5 py-4">
                        <QRCode
                          value={`${buyersPortalUrl}?shortlist-key=${shortlistPropertyHash}`}
                          style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                          }}
                        />
                      </div>
                    )}
                  </CSSTransition>
                )}

                <p className="mb-12 text-center text-[35px] font-bold">
                  Scan the QR code to save your shortlist
                </p>

                <div className="mb-9 w-400px">
                  <p className="mb-2.5 text-sm font-semibold tracking-wider">
                    INCLUDE
                  </p>
                  <div className="custom-scrollbar relative flex max-h-[116px] flex-col gap-3 overflow-y-auto rounded-[5px] border border-[#DDD] bg-neutral-grey px-5 py-3">
                    {!shortlistDocumentsResponse.isLoaded ? (
                      <div className="flex justify-center">
                        <LoadingSvg className="h-6 w-6 fill-black text-[#DDD]" />
                      </div>
                    ) : (
                      <>
                        {documentsList.length > 0 ? (
                          <>
                            {documentsList?.map((item) => (
                              <div
                                key={item.id}
                                className="flex items-center gap-3"
                              >
                                <FileSvg
                                  size="m"
                                  className="h-4 w-4 text-gray-400"
                                />
                                <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-base font-medium">
                                  {item.title || item.fileName}
                                </p>
                                {selectedDocuments.findIndex(
                                  (selected) => selected === item.id
                                ) > -1 ? (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      !isConnected &&
                                      handleUnselectDocument(item.id)
                                    }
                                  >
                                    <CheckmarkCircleSvg
                                      size="s"
                                      className="h-6 w-6 text-mainColour"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      !isConnected && handleSelectDocument(item)
                                    }
                                  >
                                    <PlusCircleSvg
                                      size="s"
                                      className="h-6 w-6 text-[#777777]"
                                      stroke={1.5}
                                    />
                                  </button>
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <span className="text-base font-medium">None</span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <SwitchTransition>
                  <CSSTransition
                    timeout={300}
                    classNames="transition-fade-out"
                    key={`${showAddAgentForm}`}
                  >
                    <div>
                      {showAddAgentForm ? (
                        <div className="w-400px">
                          <AddAgentForm
                            onCancel={handleResetAddAgentForm}
                            onConfirm={handleAddAgent}
                            isProcessing={
                              isFetchingShortlistAgentByEmailOrId ||
                              isCreatingAgent
                            }
                            errors={addAgentErrors}
                          />
                        </div>
                      ) : (
                        <div className="w-400px">
                          <p className="mb-2.5 text-sm font-semibold tracking-wider">
                            AGENT
                          </p>
                          {!shortlistAgentsResponse.isLoaded ? (
                            <div className="flex justify-center rounded-[5px] border border-[#DDD] bg-neutral-grey px-5 py-3">
                              <LoadingSvg className="h-6 w-6 fill-black text-[#DDD]" />
                            </div>
                          ) : (
                            <>
                              {selectedAgent ? (
                                <div className="flex items-center justify-between rounded-[5px] border-[#DDD] bg-neutral-grey px-5 py-3">
                                  <p className="font-medium">
                                    {selectedAgent.firstName}{' '}
                                    {selectedAgent.lastName}
                                  </p>
                                  {!session?.connected && (
                                    <button
                                      className="flex items-center"
                                      type="button"
                                      onClick={clearSelectedAgent}
                                    >
                                      <CloseSvg
                                        className="h-3.5 w-3.5 cursor-pointer"
                                        strokeColor="black"
                                      />
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <DropDown
                                  items={agentOptions}
                                  value={
                                    showAddAgentForm
                                      ? 'new'
                                      : selectedAgent ?? ''
                                  }
                                  onSelect={(value) =>
                                    !isConnected && handleAgentChange(value)
                                  }
                                  className="!border-[#DDD] bg-neutral-grey !px-5 !py-3 text-base"
                                  optionClassName="!border-[#DDD] !bg-neutral-grey !px-5 !py-4"
                                  placeholder="None"
                                  buttonRadius="rounded-[5px]"
                                  buttonRadiusClasses="rounded-tl-[5px] rounded-tr-[5px]"
                                  optionRadiusClasses="rounded-bl-[5px] rounded-br-[5px]"
                                  useValueAsKey
                                />
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          )}
        </DataHandler>
      </Container>
    </>
  )
}

export default connect(
  ({
    shortlist,
    projectIdentity,
    projectIdentity: { projectName },
    projectConfig,
    firestore: { session },
    appConfig: { shortlistPropertyHash },
  }: RootStateTypeExtra) => ({
    shortlist,
    projectIdentity,
    projectName,
    projectConfig,
    session,
    shortlistPropertyHash,
  })
)(Shortlisted)

import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import { setFilter } from '@store/actionSlices/unitFilter'
import type {
  Channel,
  ProjectIdentity,
  RootStateTypeExtra,
  SessionMap,
  UnitFilterInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FilterPopup from '@components/filter-popup'
import IdleTimeHandler from '@components/idle-time-handler'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import {
  Level,
  Unit,
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import { StatusLabels } from '@api/config'
import {
  MapContent,
  MappingBlockCollection,
  MappingCollection,
  selectFromResult as selectFromResultInteractive,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import { getQueryStringParams } from '@utilities/helper'
import LightMapHandler from '@utilities/lightmap-handler'
import styleUtil from '@utilities/style-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import { ArrowSvg, FilterSvg, MapPinSvg } from '@svg/react'

import LevelViewSkeleton from './level-view-skeleton'

export interface LevelProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  building: BuildingInterface
  floorplan: MappingCollection
  blocks: MappingBlockCollection
  blockOrders: Array<string>
  levels: Array<Level>
  unitFilter: UnitFilterInterface
  storeActiveBlock: string
  showPrice: boolean
  lightUpBuildingModelOnFilter: boolean
  levelMarkerColour: string
  channels: Array<Channel>
  statusLabels: StatusLabels
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

const LevelView = ({
  session,
  projectIdentity,
  floorplan,
  building,
  blocks: storeBlocks,
  blockOrders,
  storeActiveBlock,
  levels,
  unitFilter,
  showPrice,
  lightUpBuildingModelOnFilter,
  levelMarkerColour,
  channels,
  statusLabels,
}: LevelProps) => {
  const themeData = styleUtil()
  const history = useHistory()
  const location = useLocation()
  const urlParams = React.useRef(getQueryStringParams(location.search))
  const dispatch = useDispatch()
  const floorplanLength = React.useMemo(
    () => Object.keys(floorplan).length,
    [floorplan]
  )
  const blockKeys = React.useMemo(
    () => (blockOrders.length > 1 ? blockOrders : Object.keys(storeBlocks)),
    [blockOrders, storeBlocks]
  )

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [rendering, setRendering] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isCanvasImageLoaded, setImageLoaded] = React.useState(false)
  const [activeFacade, setFacade] = React.useState(0)
  const fireStoreActiveBlock =
    (session?.connected && session?.building?.activeBlock) || undefined
  const [blockFocus, setBlockFocus] = React.useState(
    blockKeys.find(
      (res) => (fireStoreActiveBlock || storeActiveBlock) === res
    ) || blockKeys[0]
  )
  const prevBlockFocusRef = React.useRef(blockFocus)

  const [isConnected, setIsConnected] = React.useState(false)
  const [theme, setTheme] = React.useState({
    font: '',
    mainColour: '',
  })
  const [lightHandlerActiveState, setLightHandlerActiveState] =
    React.useState(true)
  const [filteredProperties, setFilteredProperties] = React.useState<
    Array<{ name: string; type: string }>
  >([])

  LightMapHandler({
    isActive: lightHandlerActiveState,
    projectIdentity,
    channels: Object.values(channels),
    activeLevel: '',
    activeUnit: '',
    shortlists: [],
    lightUpBuildingModelOnFilter,
    filteredApplied: unitFilter.apply,
    filteredItems: unitFilter.apply ? filteredProperties : [],
  })

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromResultInteractive }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const [facadeControl, setFacadeControl] = React.useState('')

  const levelDataByBlock = React.useCallback(
    (foundLevel: Level) =>
      blockKeys.length > 1
        ? foundLevel.data.filter((unit) => unit.blockId === storeActiveBlock)
        : foundLevel.data,
    [blockKeys, storeActiveBlock]
  )

  const getAvailableUnitCount = React.useCallback(
    (poly: Polygon) => {
      const foundLevel = levels.find((res) => poly.groupId === res.level)
      if (foundLevel) {
        return levelDataByBlock(foundLevel).filter(
          (res) => res.metas.status === 'available'
        ).length
      }
      return 0
    },
    [levels, levelDataByBlock]
  )

  const findRelevantLevel = React.useCallback(
    (levelName: string) => levels.find((res) => levelName === res.level),
    [levels]
  )

  const canUnitFilterByBlock = React.useCallback(
    (unit: Unit) =>
      storeActiveBlock && unit.blockId && unit.blockId !== storeActiveBlock,
    [storeActiveBlock]
  )

  const getLevelStatus = React.useCallback(
    (poly: Polygon) => {
      const { apply } = unitFilter
      const splicdeGroupId = poly.groupId.split('-')
      const foundLevel = findRelevantLevel(splicdeGroupId[1] || poly.groupId)

      let levelActive = false

      if (!apply || !foundLevel) {
        return levelActive
      }

      levelDataByBlock(foundLevel).forEach((unit) => {
        if (canUnitFilterByBlock(unit)) {
          return
        }
        if (splicdeGroupId[1]) {
          if (unit.blockId !== splicdeGroupId[0]) {
            return
          }
        }
        if (!levelActive) {
          levelActive = filterUnitUtil(
            unit,
            unitFilter,
            showPrice,
            statusLabels?.available
          )
        }
      })

      return levelActive
    },
    [
      unitFilter,
      findRelevantLevel,
      levelDataByBlock,
      canUnitFilterByBlock,
      showPrice,
    ]
  )

  const getLevelUnitCount = React.useCallback(
    (poly: Polygon) => {
      const splicdeGroupId = poly.groupId.split('-')
      const foundLevel = findRelevantLevel(splicdeGroupId[1] || poly.groupId)

      if (!foundLevel) return 0

      return levelDataByBlock(foundLevel).filter((unit: Unit) =>
        filterUnitUtil(unit, unitFilter, showPrice, statusLabels?.available)
      ).length
    },
    [findRelevantLevel, levelDataByBlock, unitFilter, showPrice, statusLabels]
  )

  const checkGroupId = React.useCallback((groupId: string) => {
    const splitGroupString = groupId.split('-')
    if (splitGroupString.length > 1) {
      const [activeBlock, activeLevel] = splitGroupString
      return {
        activeBlock,
        activeLevel,
      }
    }
    return {
      activeLevel: groupId,
      activeBlock: '',
    }
  }, [])

  const interactiveClick = React.useCallback(
    (poly: Polygon) => {
      setLightHandlerActiveState(false)
      const { activeLevel, activeBlock } = checkGroupId(poly.groupId || '')
      dispatch(setByFlag({ flag: 'activeLevel', value: activeLevel }))
      dispatch(
        setByFlag({
          flag: 'activeBlock',
          value: activeBlock || (blockKeys.length > 1 ? blockFocus : ''),
        })
      )
      dispatch(setByFlag({ flag: 'activeUnit', value: '' }))

      if (!isConnected) {
        history.push('building')
      }
    },
    [checkGroupId, blockKeys, blockFocus, isConnected]
  )

  const getLabel = React.useCallback((poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return `${poly.label} ${poly.groupId}`
  }, [])

  const setInteractiveAction = React.useCallback(
    (areaViewMap: MapContent) => ({
      ...areaViewMap,
      polygons: areaViewMap.polygons
        .filter((poly) =>
          findRelevantLevel(poly.groupId?.split('-')?.[1] || poly.groupId)
        )
        .map((poly) => ({
          ...poly,
          activeByDefault: getLevelStatus(poly),
          label: getLabel(poly),
          isHidden: getLevelUnitCount(poly) === 0,
          subLabel: poly?.subLabel
            ? `${getLevelUnitCount(poly)} ${poly.subLabel}`
            : '',
          postFix: `- Available Units (${getAvailableUnitCount(poly)})`,
          onClick: () => interactiveClick(poly),
          markerColour: levelMarkerColour,
        })),
    }),
    [
      findRelevantLevel,
      getLevelStatus,
      getLabel,
      getLevelUnitCount,
      getAvailableUnitCount,
      interactiveClick,
      levelMarkerColour,
    ]
  )

  const block = React.useMemo(() => {
    if (!(blockFocus === '' || blockFocus)) {
      return []
    }
    if (!storeBlocks[blockFocus]) {
      return (
        Object.values(storeBlocks)?.[0]?.map((res) =>
          setInteractiveAction(res)
        ) || []
      )
    }
    return (
      storeBlocks[blockFocus]?.map((res) => setInteractiveAction(res)) || []
    )
  }, [blockFocus, storeBlocks, setInteractiveAction])

  const handleClick = () => {
    const facadeLength = block.length - 1
    if (activeFacade < facadeLength) {
      setFacade(activeFacade + 1)
    } else {
      setFacade(0)
    }
  }

  const handleFaceController = (facadeHash: string) => {
    if (facadeControl !== facadeHash) {
      handleClick()
      setFacadeControl(facadeHash)
    }
  }

  const handleLevelControl = (level: string, activeBlock: string) => {
    if (activeBlock) {
      if (
        block[activeFacade]?.polygons?.find(
          (res) => res.groupId === `${activeBlock}-${level}`
        )
      ) {
        canvasRef?.current?.artificialTrigger(`${activeBlock}-${level}`)
        return
      }
    }
    canvasRef?.current?.artificialTrigger(level)
  }

  const handleActiveBlockControl = (activeBlock: string) => {
    if (activeBlock === '') {
      setBlockFocus(blockKeys[0])
      return
    }
    if (blockKeys.find((res) => res === activeBlock)) {
      setBlockFocus(activeBlock)
    }
  }

  const getLevelListByBlockId = (blockId: string): Array<string> => {
    const levelList: Level[] = [...levels]
    if (unitFilter.showAvailable) {
      levelList.filter((lvl: Level) =>
        lvl.data.filter(
          (unit: Unit) => unit.metas.status === statusLabels?.available
        )
      )
    }
    return levelList
      .filter((res) => res.data.some((unit) => unit.blockId === blockId))
      .map((res) => res.level)
  }

  React.useEffect(() => {
    if (!urlParams.current?.block) {
      return
    }
    history.replace(location.pathname)
  }, [location.pathname])

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (!floorplanLength && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload, floorplanLength])

  React.useEffect(() => {
    const { status: interactivePayloadStatus } = interactivePayload
    if (interactivePayloadStatus !== 'fulfilled' || !floorplanLength) {
      return
    }
    const { building: buildingData, blockOrders: blockOrdersData } =
      buildingPayload

    if (building.levels.length === 0 && buildingData.length > 0) {
      const orderedBlockKeys: string[] =
        blockOrdersData.length > 1 ? blockOrdersData : Object.keys(storeBlocks)
      const activeBlock =
        orderedBlockKeys.find(
          (res) =>
            (fireStoreActiveBlock ||
              building.activeBlock ||
              urlParams.current?.block) === res
        ) ||
        orderedBlockKeys[0] ||
        buildingData[0]?.data[0].blockId ||
        ''

      dispatch(
        setBuilding({
          ...building,
          levels: buildingData,
          blockOrders: blockOrdersData,
          activeBlock,
        })
      )
      setBlockFocus(activeBlock)
    }
  }, [
    buildingPayload,
    interactivePayload,
    building,
    floorplanLength,
    storeBlocks,
    fireStoreActiveBlock,
  ])

  React.useEffect(() => {
    if (!rendering) {
      canvasRef?.current?.setPolyActive()
    }
  }, [block, rendering])

  React.useEffect(() => {
    dispatch(setByFlag({ flag: 'activeBlock', value: blockFocus }))
    if (prevBlockFocusRef.current === blockFocus) {
      return
    }
    prevBlockFocusRef.current = blockFocus
    setTimeout(() => {
      canvasRef?.current?.setCanvas()
    }, 100)
  }, [blockFocus])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: {
          activeFacade: fireStoreActiveFacade,
          activeLevel: level,
          activeBlock,
          filterPopup,
          unitFilter: unitFilterFirestore,
        },
      } = session
      if (connected) {
        toggleFilter(filterPopup)
        if (fireStoreActiveFacade && renderCanvas) {
          handleFaceController(fireStoreActiveFacade)
        }
        if (level && renderCanvas) {
          handleLevelControl(level, activeBlock)
        }
        handleActiveBlockControl(activeBlock)
        setIsConnected(connected)
        dispatch(setFilter(unitFilterFirestore))
      }
    }
  }, [session])

  React.useEffect(() => {
    const output: Array<{ name: string; type: string }> = []
    if (!unitFilter.apply) {
      setFilteredProperties(output)
      return
    }
    const myPolygons = block[activeFacade]?.polygons || []
    myPolygons.forEach((poly) => {
      if (!poly.isHidden) {
        output.push({ name: poly.groupId, type: 'level' })
      }
    })
    setFilteredProperties(output)
  }, [block, activeFacade, unitFilter.apply])

  React.useEffect(() => {
    setRenderCanvas(true)
    if (blockKeys.length > 1) {
      const [firstLevel] = getLevelListByBlockId(storeActiveBlock)
      dispatch(setByFlag({ flag: 'activeLevel', value: firstLevel }))
    }
  }, [statusLabels])

  React.useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  return (
    <>
      <Container>
        <DataHandler
          payload={{
            ...buildingPayload,
            data: building.levels,
            apiData: buildingPayload.building,
          }}
          skeletonFrame={<LevelViewSkeleton />}
        >
          <div
            className={`absolute left-5 top-5 z-20 ${
              isConnected ? 'invisible' : ''
            }`}
          >
            <IdleTimeHandler>
              <div className="flex items-center gap-4">
                <Link
                  to="area-view"
                  className="rounded bg-white p-1 drop-shadow-40"
                >
                  <ArrowSvg className="h-8 w-8" strokeColor="#000" />
                </Link>
                <button
                  data-testid="toggle-filter"
                  onClick={() => toggleFilter(!isFilterOpen)}
                  type="button"
                  className="rounded bg-white p-2.5 drop-shadow-40"
                >
                  <FilterSvg className="h-5 w-5" />
                </button>
                {blockKeys.length > 1 && (
                  <button
                    data-testid="toggle-filter"
                    onClick={() => toggleFilter(!isFilterOpen)}
                    type="button"
                    className="flex items-center gap-1.5 rounded bg-white p-2 drop-shadow-40"
                  >
                    <MapPinSvg />
                    <span>{blockFocus}</span>
                  </button>
                )}
              </div>
            </IdleTimeHandler>
          </div>
          <DataHandler
            payload={{
              ...interactivePayload,
              data: floorplanLength,
              apiData: interactivePayload.maps?.areaView,
            }}
            skeletonFrame={<LevelViewSkeleton />}
          >
            <ImageHandler
              key={block[activeFacade]?.image}
              url={block[activeFacade]?.image}
              type="new"
              className="background-cover image-blur absolute inset-0 z-2"
              noSpliceUrl
              showFallbackImage={false}
              bgProps={{
                gradiant: 0.5,
              }}
            />

            <div className="absolute inset-0 z-3">
              <div
                ref={canvasContainerRef}
                className={`relative m-auto flex h-full w-full items-center justify-center ${
                  isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
                }`}
              >
                {canvasContainerRef && renderCanvas && block[activeFacade] && (
                  <CanvasInteractive
                    id="level-canvas"
                    ref={canvasRef}
                    canvasData={block[activeFacade]}
                    parentRef={canvasContainerRef}
                    hasLabel={!isConnected}
                    labelPrefix="Level:"
                    isRendering={setRendering}
                    theme={{
                      brandColour:
                        theme?.mainColour || themeData.mainColour || '',
                      font: theme?.font || themeData.font || '',
                    }}
                    adjustCanvasSizeWithContainer
                    setImageLoaded={setImageLoaded}
                  />
                )}
              </div>
            </div>
          </DataHandler>
          <FilterPopup
            isOpen={isFilterOpen}
            toggle={toggleFilter}
            onChangeBlock={(item: string) => setBlockFocus(item)}
            hideLevelOption
          />
        </DataHandler>
      </Container>
    </>
  )
}

export default connect(
  ({
    unitFilter,
    firestore: { session, lightMap },
    interactivePlan: { blocks, floorplan },
    building,
    projectConfig: {
      showPrice,
      markerColour: { levels: levelMarkerColour },
      lightUpBuildingModelOnFilter,
      statusLabels,
    },
    projectIdentity,
  }: RootStateTypeExtra) => ({
    unitFilter,
    session,
    floorplan,
    building,
    blocks,
    blockOrders: building.blockOrders,
    storeActiveBlock: building.activeBlock,
    levels: building.levels,
    buildingActiveLevel: building.activeLevel,
    aspects: building.aspects,
    showPrice,
    levelMarkerColour,
    lightUpBuildingModelOnFilter,
    projectIdentity,
    channels: lightMap?.channels || [],
    statusLabels,
  })
)(LevelView)

import React from 'react'
import { useSwipeable } from 'react-swipeable'

import useScript from '@utilities/adgroup-utilities/hooks/script'
import AssetHandler from '@utilities/asset-handler'

import './pannellum.css'
import {
  PannellumDataInterface,
  PannellumHotspotInterface,
  PannellumSceneInterface,
  PannellumViewRefInterface,
} from './types'
import constants from './utilities/constants'

declare global {
  interface Window {
    pannellum: any
  }
}

interface PannellumViewPropInterface {
  payload: Array<PannellumDataInterface>
  activeScene?: string
  sceneFadeDuration?: number
  autoLoad?: boolean
  showControls?: boolean
  className?: string
  viewerInit?: (arg: boolean) => void
  swipeHandler?: () => void
  useControlImg?: boolean
  panoramaType?: 'sphere' | 'rect'
  hideHotspots?: boolean
  onSceneChange?: (newSceneId: string) => void
}

const { pannellumJsScript } = constants

const PannellumView = React.forwardRef<
  PannellumViewRefInterface | undefined,
  PannellumViewPropInterface
>(
  (
    {
      payload,
      activeScene = '',
      sceneFadeDuration = 1000,
      autoLoad = true,
      showControls = false,
      className = 'h-screen w-screen',
      viewerInit,
      swipeHandler,
      useControlImg,
      panoramaType = 'sphere',
      hideHotspots = false,
      onSceneChange,
    },
    ref
  ) => {
    const [scriptLoaded, setScriptLoadingState] = React.useState(false)

    const viewerRef = React.useRef<any>()
    const [pannellumSDKLoadError, setPannellumSDKLoadError] =
      React.useState(false)
    const [viewerLoaded, setViewerLoadState] = React.useState(false)

    useScript(pannellumJsScript, {
      onLoadCallback: () => setScriptLoadingState(true),
      onErrorCallback: (err) => {
        setPannellumSDKLoadError(true)
        console.error('Pannellum SDK failed to load!', err)
      },
    })

    const handleViewerLoad = () => setViewerLoadState(true)

    const buildAssetUrl = (url = '') =>
      url !== '' ? AssetHandler({ url, type: 'new' }) : ''

    const setSDKConfig = () => {
      if (panoramaType === 'rect') {
        return {
          maxYaw: 175,
          minYaw: -175,
          maxPitch: 50,
          minPitch: -45,
          minHfov: 50,
          maxHfov: 100,
          haov: 360,
          vaov: 100,
          vOffset: 1.17,
        }
      }
      return {}
    }

    const handleSceneChange = (newSceneId: string) => {
      onSceneChange?.(newSceneId)
    }

    const handlePannellumSDKOnLoad = (
      myPayload: Array<PannellumDataInterface>
    ) => {
      const scenes: { [key: string]: PannellumSceneInterface } = {}
      myPayload.forEach((data: PannellumDataInterface) => {
        const { nameMap, label, pitch, yaw, imageUrl, controlUrl, hotspots } =
          data

        const image = useControlImg ? controlUrl || imageUrl : imageUrl

        scenes[nameMap] = {
          title: label || nameMap,
          pitch,
          yaw,
          type: 'equirectangular',
          panorama: buildAssetUrl(image),
          hotSpots: hideHotspots
            ? []
            : (hotspots || []).map((hotspot: PannellumHotspotInterface) => ({
                ...hotspot,
              })),
        }
        return data
      })
      const firstScene = activeScene || ''
      if (firstScene && !viewerRef.current) {
        viewerRef.current = window.pannellum.viewer('panorama-view', {
          sceneFadeDuration,
          autoLoad,
          showControls,
          default: {
            firstScene,
            sceneFadeDuration,
          },
          scenes,
          ...setSDKConfig(),
        })
        if (viewerInit) {
          viewerInit(true)
        }
      }

      if (viewerRef.current) {
        viewerRef.current.on('load', handleViewerLoad)
        viewerRef.current.on('scenechange', handleSceneChange)
      }
    }

    const getBackgroundImage = React.useCallback(() => {
      if (payload.length < 1) {
        return ''
      }

      const foundItem = payload.find(
        (item: PannellumDataInterface) => item.nameMap === activeScene
      )

      const activeItem = foundItem || payload[0]

      const { imageUrl, controlUrl } = activeItem

      const image = controlUrl || imageUrl

      return buildAssetUrl(image)
    }, [payload, activeScene])

    const { ref: refSwipe, onMouseDown: onMouseDownSwipe } = useSwipeable({
      onSwiping: () => swipeHandler && swipeHandler(),
      onSwiped: () => swipeHandler && swipeHandler(),
      onSwipeStart: () => swipeHandler && swipeHandler(),
      trackTouch: true,
      trackMouse: true,
    })

    React.useImperativeHandle(ref, () => ({
      pannellum: viewerRef?.current,
    }))

    React.useEffect(() => {
      if (payload.length > 0 && scriptLoaded) {
        handlePannellumSDKOnLoad(payload)
      }
    }, [payload, activeScene, scriptLoaded])

    React.useEffect(
      () => () => {
        if (viewerRef.current) {
          viewerRef.current.off('load', handleViewerLoad)
          viewerRef.current.off('scenechange', handleSceneChange)
        }
      },
      []
    )

    return (
      <>
        {pannellumSDKLoadError ? (
          <p>Failed to load Pannellum SDK</p>
        ) : (
          <div
            style={{
              backgroundImage: `${
                viewerLoaded ? 'none' : `url(${getBackgroundImage()})`
              }`,
            }}
            tabIndex={0}
            role="button"
            ref={refSwipe}
            onMouseDown={onMouseDownSwipe}
            className={`${className} ${viewerLoaded ? '' : 'bg-cover blur-sm'}`}
            id="panorama-view"
          ></div>
        )}
      </>
    )
  }
)

export default PannellumView

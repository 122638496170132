import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setInteractiveMap } from '@store/actionSlices/interactiveMap'
import { RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import MapStack, { Layer } from '@components/showcase-stack/map-stack'

import {
  Stack,
  selectFromResult,
  useGetInteractiveMapQuery,
} from '@api/interactive-map'

import InteractiveMapSkeleton from './interactive-map-skeleton'
import LayerCategoryPanel from './layer-panel/layer-category-panel'

export interface LayerExtended extends Layer {
  category: string
  label: string
  controlSrc: string
  baseImageOverride: string
  categoryDefault: boolean
  actionRoute: string
  at?: number
}

export interface InteractiveMapProps {
  session: SessionMap | undefined
  projectName: string
  interactiveMap: Stack
}

const InteractiveMap = ({
  session,
  projectName,
  interactiveMap,
}: InteractiveMapProps) => {
  const [isConnected, setIsConnected] = React.useState(false)
  const mapContainerRef = React.useRef<HTMLDivElement>(null)
  const [categories, setCategories] = React.useState<Array<string>>([])
  const interactiveMapRef = React.useRef<Stack>()
  const dispatch = useDispatch()

  const interactiveMapPayload = useGetInteractiveMapQuery(
    { projectName },
    { selectFromResult }
  )
  const defaultBackground = interactiveMapPayload?.stack?.src

  const [layerBackground, setLayerBackground] = React.useState('')
  const [categoryBackground, setCategoryBackground] = React.useState('')
  const backgroundImage = React.useMemo(
    () => categoryBackground || layerBackground || defaultBackground,
    [categoryBackground, layerBackground, defaultBackground]
  )

  const handleAnimationLayer = (layer: LayerExtended) => ({
    ...layer,
    src: `${layer.src.split('?')[0]}?${Math.random()}`,
    animation: layer?.animation
      ? `${layer.animation.split('?')[0]}?${Math.random()}`
      : '',
    visible: true,
  })

  const setRelevantBackground = (
    layers: LayerExtended[],
    layer: LayerExtended | undefined
  ) => {
    let canSetLayerBackground = false
    layers.forEach((lyr) => {
      if (lyr.groupId === layer?.groupId) {
        if (lyr.visible && lyr.baseImageOverride) {
          canSetLayerBackground = true
        }
      }
    })
    if (canSetLayerBackground && layer?.baseImageOverride) {
      setLayerBackground(layer.baseImageOverride)
      return
    }
    const visibleLayer = layers.find((lyr) => lyr.visible)
    setLayerBackground(visibleLayer?.baseImageOverride || interactiveMap.src)
  }

  const handleLayerTrigger = (trigger: Array<string>) => {
    const interactiveMapStack = interactiveMap.layers.length
      ? interactiveMap
      : interactiveMapPayload.stack

    if (!interactiveMapStack || interactiveMapStack.layers.length === 0) {
      return
    }

    const layers = interactiveMapStack.layers.map((res) => {
      if (!res.controls) {
        return res
      }
      if (!trigger.find((trg) => trg === res.groupId)) {
        return {
          ...res,
          visible: false,
        }
      }
      if (res.visible) {
        return res
      }
      if (res.type.toLowerCase() === 'animation') {
        return handleAnimationLayer(res)
      }
      return {
        ...res,
        visible: true,
      }
    })

    const layer = interactiveMapStack.layers.find(
      (lyr) => trigger.includes(lyr.groupId) && !lyr.visible
    )

    setRelevantBackground(layers, layer)

    dispatch(
      setInteractiveMap({
        ...interactiveMapStack,
        layers,
      })
    )
  }

  const filterCategories = (layers: Array<LayerExtended>) => {
    const newCategories: string[] = []

    layers.forEach((layer: LayerExtended) => {
      if (!newCategories.includes(layer.category) && layer.controls) {
        newCategories.push(layer.category)
      }
    })

    setCategories(newCategories)
  }

  React.useEffect(() => {
    const { stack } = interactiveMapPayload
    if (interactiveMap.layers.length === 0 && stack.layers.length > 0) {
      dispatch(setInteractiveMap(stack))
    }
  }, [interactiveMapPayload, interactiveMap])

  React.useEffect(() => {
    if (interactiveMap.layers.length > 0) {
      filterCategories(interactiveMap.layers)
    }
    interactiveMapRef.current = { ...interactiveMap }
  }, [interactiveMap])

  React.useEffect(() => {
    if (session) {
      const { interactiveMap: interactiveMapSession, connected } = session
      handleLayerTrigger(interactiveMapSession)
      setIsConnected(connected)
    }
  }, [session?.interactiveMap, session?.connected, interactiveMapPayload])

  React.useEffect(() => {
    if (session) {
      const {
        interactiveMapActiveCategory: { baseImageOverride },
      } = session

      setCategoryBackground(baseImageOverride)
    }
  }, [session?.interactiveMapActiveCategory, interactiveMap])

  React.useEffect(
    () => () => {
      if (!interactiveMapRef.current) return
      dispatch(
        setInteractiveMap({
          ...interactiveMapRef.current,
          layers: interactiveMapRef.current.layers.map((layer) => ({
            ...layer,
            visible: false,
          })),
        })
      )
    },
    []
  )

  return (
    <div>
      <Container
        background={{
          url: backgroundImage,
          type: 'new',
          noSpliceUrl: true,
        }}
      >
        <DataHandler
          payload={{
            ...interactiveMapPayload,
            data: interactiveMap.layers,
            apiData: interactiveMapPayload.stack?.layers,
          }}
          skeletonFrame={<InteractiveMapSkeleton />}
        >
          <div ref={mapContainerRef} className="relative h-screen w-screen">
            {interactiveMap?.layers && mapContainerRef && (
              <>
                <MapStack
                  id="interactive-map"
                  background={backgroundImage}
                  layers={interactiveMap.layers}
                  heightClass={isConnected ? 'h-screen' : 'h-page-container'}
                />
                <IdleTimeHandler>
                  {categories.length > 0 && !isConnected ? (
                    <LayerCategoryPanel
                      categories={categories}
                      interactiveMap={interactiveMap}
                      setLayerBackground={setLayerBackground}
                      setCategoryBackground={setCategoryBackground}
                    />
                  ) : null}
                </IdleTimeHandler>
              </>
            )}
          </div>
        </DataHandler>
      </Container>
    </div>
  )
}

export default connect(
  ({
    firestore: { session },
    projectIdentity: { projectName },
    interactiveMap,
  }: RootStateTypeExtra) => ({
    session,
    projectName,
    interactiveMap,
  })
)(InteractiveMap)

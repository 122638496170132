import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'

import firebaseConfig, { getFirebaseLocalHost } from './firebaseConfig'

const app = initializeApp(firebaseConfig)

const firestore = getFirestore(app)

const { firebasePort, firebaseIp } = getFirebaseLocalHost()

if (firebasePort && firebaseIp) {
  connectFirestoreEmulator(
    firestore,
    firebaseIp || 'localhost',
    Number(firebasePort)
  )
}

export default firestore

import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setByFlag } from '@store/actionSlices/building'
import type { RootStateTypeExtra, SessionMap } from '@store/types'
import { UnitFilterInterface } from '@store/types'

import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { MarkerType } from '@components/showcase-canvas/constants'
import { Polygon } from '@components/showcase-canvas/types'

import { Level, Unit } from '@api/building'
import { MarkerColourInterface, StatusLabels } from '@api/config'
import { MapContent, MappingCollection } from '@api/interactive-plan'

import analyzePixels from '@utilities/analyze-pixels'
import AssetHandler from '@utilities/asset-handler'
import styleUtil from '@utilities/style-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import { CloseSvg, FullScreenSvg } from '@svg/react'

export interface FloorplanCanvasProps {
  activeLevel: string
  activeBlock: string
  isFullWidth: boolean
  floorplan: MappingCollection
  session: SessionMap | undefined
  unitFilter: UnitFilterInterface
  units: Array<Unit>
  markerColour: MarkerColourInterface
  showPrice: boolean
  unitPrefix: string
  ratio?: string
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  disableSoldUnit: boolean
  statusLabels: StatusLabels
}

const FloorplanCanvas = ({
  units,
  unitFilter,
  floorplan,
  session,
  activeLevel,
  activeBlock,
  isFullWidth: isFullWidthProp,
  markerColour,
  showPrice,
  unitPrefix,
  ratio = 'max',
  fullScreenToggle,
  setFullScreenToggle,
  disableSoldUnit,
  statusLabels,
}: FloorplanCanvasProps) => {
  const themeData = styleUtil()
  const dispatch = useDispatch()

  const prevReference = React.useRef<{
    activeLevel: string
    activeBlock: string
    activeImage: string
  }>({
    activeLevel,
    activeBlock,
    activeImage: '',
  })

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()

  const [isFloorPlanImageLoaded, setImageLoaded] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const [isFullWidth, setIsFullWidth] = React.useState(isFullWidthProp)
  const [backgroundColor, setBackgroundColor] =
    React.useState('rgb(255, 255, 255)')

  const [theme, setTheme] = React.useState({
    font: '',
    mainColour: '',
  })

  const [floorplanMap, setFloorPlanMap] = React.useState<MapContent>()

  const getFloorplanLength = () => Object.keys(floorplan).length > 0

  const arrowMarkerFilterLogic = (unit: Unit) => {
    const { apply } = unitFilter

    if (!apply) {
      return false
    }

    return !filterUnitUtil(unit, unitFilter, showPrice, statusLabels?.available)
  }

  const badgeAndNumberedFilterLogic = (unit: Unit) => {
    const { apply } = unitFilter

    if (!apply) {
      return false
    }

    return filterUnitUtil(unit, unitFilter, showPrice, statusLabels?.available)
  }

  const checkUnitStatus = (unit: Unit, type: string) => {
    if (disableSoldUnit && unit.metas.status !== statusLabels?.available) {
      return true
    }
    if (type === MarkerType.arrowSmall || type === MarkerType.arrow) {
      return arrowMarkerFilterLogic(unit)
    }
    return badgeAndNumberedFilterLogic(unit)
  }

  const isSoldUnitDisabled = (unit: Unit) => {
    if (disableSoldUnit && unit.metas.status !== statusLabels?.available) {
      return true
    }
    return !unit?.metas
  }

  const setUnitColor = (status: string, poly: Polygon) => {
    if (poly.type === MarkerType.arrowSmall) {
      return poly?.color
    }
    if (status === statusLabels?.available) {
      return poly?.color || '#0abec4'
    }
    return '#8F8E8E'
  }

  const getMarkerColour = (status: string) =>
    status === statusLabels?.available
      ? markerColour.available
      : markerColour.sold

  const handleUnitData = (poly: Polygon) => {
    const unit = units.find(
      (res) => res.name.replace(/\s/g, '') === poly?.groupId?.replace(/\s/g, '')
    )
    return {
      disabled: unit && isSoldUnitDisabled(unit),
      postFix: unit?.metas.status,
      color: setUnitColor(unit?.metas.status || '', poly),
      markerColor: getMarkerColour(unit?.metas.status || ''),
      activeByDefault: unit && checkUnitStatus(unit, poly?.type || ''),
    }
  }

  const mapFloorplanActions = React.useCallback(() => {
    const mapName = activeBlock ? `${activeBlock}-${activeLevel}` : activeLevel
    const currentMap: MapContent = floorplan[mapName]
    if (!currentMap) {
      return
    }
    setFloorPlanMap({
      ...currentMap,
      image: currentMap.image,
      polygons: currentMap.polygons
        .map((poly) => ({
          ...poly,
          ...handleUnitData(poly),
          onClick: () => {
            if (poly.groupId) {
              dispatch(setByFlag({ flag: 'activeUnit', value: poly.groupId }))
            }
          },
        }))
        .filter((res) =>
          units.find(
            (resInner) =>
              resInner.name.replace(/\s/g, '') ===
              res?.groupId?.replace(/\s/g, '')
          )
        ),
    })
  }, [activeLevel, activeBlock, unitFilter, units, statusLabels])

  const handleFullScreenToggle = () => {
    setFullScreenToggle(!fullScreenToggle)
    setImageLoaded(false)
    requestAnimationFrame(() => {
      canvasRef?.current?.setCanvas()
    })
  }

  const handleBackgroundColor = () => {
    if (floorplanMap) {
      const url = AssetHandler({
        url: floorplanMap.image,
        type: 'new',
        noSpliceUrl: true,
      })
      const dynamicImage = document.createElement('img')
      dynamicImage.crossOrigin = 'Anonymous'
      dynamicImage.src = url
      dynamicImage.onload = () => {
        const color = analyzePixels(dynamicImage)
        const rgb = `rgb(${color[0]}, ${color[1]}, ${color[2]})`
        setBackgroundColor(rgb)
      }
    }
  }

  React.useEffect(() => {
    if (getFloorplanLength() && canvasContainerRef && units.length > 0) {
      mapFloorplanActions()
      setRenderCanvas(true)
    }
  }, [activeLevel, activeBlock, unitFilter, units, statusLabels])

  const handleCanvasItems = () => {
    canvasRef?.current?.setPolyActive()
  }

  React.useEffect(() => {
    if (!floorplanMap) {
      return
    }
    if (
      activeLevel !== prevReference.current.activeLevel ||
      activeBlock !== prevReference.current.activeBlock ||
      floorplanMap.image !== prevReference.current.activeImage
    ) {
      setTimeout(() => canvasRef?.current?.setCanvas(), 200)
    }
    prevReference.current = {
      ...prevReference.current,
      activeLevel,
      activeBlock,
      activeImage: floorplanMap.image,
    }
    handleCanvasItems()
  }, [floorplanMap])

  React.useEffect(() => {
    if (isFullWidth !== isFullWidthProp) {
      requestAnimationFrame(() => {
        canvasRef?.current?.setCanvas()
      })
      setIsFullWidth(isFullWidthProp)
    }
  }, [isFullWidthProp])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        building: { activeUnit },
      } = session
      setIsConnected(connected)
      canvasRef?.current?.artificialTrigger(activeUnit)
    }
  }, [session])

  React.useLayoutEffect(() => {
    handleBackgroundColor()
  }, [activeLevel, activeBlock])

  React.useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  return (
    <div
      className="relative h-full w-full bg-white"
      style={{ backgroundColor }}
    >
      {!isConnected && (
        <div className="absolute left-0 top-0 z-10 p-4">
          <button
            type="button"
            onClick={handleFullScreenToggle}
            className="rounded bg-[#747474] p-2"
          >
            {fullScreenToggle ? (
              <CloseSvg className="h-6 w-6 text-white" />
            ) : (
              <FullScreenSvg className="h-6 w-6 text-white" />
            )}
          </button>
        </div>
      )}
      <div className="h-full w-full">
        <div
          className={`relative flex aspect-video h-full w-screen items-center justify-center transition ease-in-out ${
            isFloorPlanImageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          style={
            !fullScreenToggle && !isConnected
              ? { maxWidth: 'calc(100vw - 297px)' }
              : undefined
          }
          ref={canvasContainerRef}
        >
          {floorplanMap && renderCanvas && (
            <CanvasInteractive
              ref={canvasRef}
              id="floorplan-canvas"
              canvasData={floorplanMap}
              parentRef={canvasContainerRef}
              hasLabel={!isConnected}
              labelPrefix={`${unitPrefix || 'Unit'}:`}
              ratio={ratio}
              theme={{
                brandColour: theme?.mainColour || themeData.mainColour || '',
                font: theme?.font || themeData.font || '',
              }}
              adjustCanvasSizeWithContainer
              setImageLoaded={() => {
                setTimeout(() => {
                  setImageLoaded(true)
                }, 300)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const findUnit = (
  level: Array<Level>,
  activeLevel: string,
  activeBlock: string
) =>
  level
    .find((lvl: Level) => lvl.level === activeLevel)
    ?.data?.filter((res) => {
      if (activeBlock) {
        return res.blockId === activeBlock
      }
      return true
    }) || []

export default connect(
  ({
    unitFilter,
    building: { activeLevel, activeBlock, levels },
    interactivePlan: { floorplan },
    projectConfig: {
      markerColour,
      showPrice,
      unitPrefix,
      disableSoldUnit,
      statusLabels,
    },
  }: RootStateTypeExtra) => ({
    unitFilter,
    activeLevel,
    activeBlock,
    floorplan,
    units: findUnit(levels, activeLevel, activeBlock),
    markerColour,
    showPrice,
    unitPrefix,
    disableSoldUnit,
    statusLabels,
  })
)(FloorplanCanvas)

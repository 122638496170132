import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  LotShortlistInterface,
  PackageConfigurationInterface,
  RootStateTypeExtra,
  ShortlistItemInterface,
  ShortlistPropertyType,
} from '@store/types'

export interface ShortlistState {
  shortlists: ShortlistItemInterface[]
  documents?: string[]
  agent?: string
}

const LOCALSTORAGE_KEY = 'shortlist'
const INITIAL_STATE = {
  shortlists: [],
  documents: [],
  agent: '',
} as ShortlistState

const shortlistSlice = createSlice({
  name: 'shortlist',
  initialState: () => {
    const shortlistData = localStorage.getItem(LOCALSTORAGE_KEY)
    let initialState = { ...INITIAL_STATE }

    if (shortlistData) {
      initialState = JSON.parse(shortlistData)
    }

    return initialState
  },
  reducers: {
    addShortlist: (
      state: ShortlistState,
      action: PayloadAction<ShortlistItemInterface>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: [...state.shortlists, action.payload],
      }

      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    updateShortlist: (
      state: ShortlistState,
      action: PayloadAction<{
        propertyId: ShortlistItemInterface['propertyId']
        data: Partial<ShortlistItemInterface>
      }>
    ) => {
      const newShortlists = [...state.shortlists]
      const matchedIndex = newShortlists.findIndex(
        (item) => item.propertyId === action.payload.propertyId
      )
      if (matchedIndex >= 0) {
        newShortlists[matchedIndex] = {
          ...newShortlists[matchedIndex],
          ...(action.payload.data as ShortlistItemInterface),
          propertyId: action.payload.propertyId,
        }
      }
      const newState: ShortlistState = {
        ...state,
        shortlists: newShortlists,
      }

      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    setShortlists: (
      state: ShortlistState,
      action: PayloadAction<ShortlistItemInterface[]>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: action.payload,
      }
      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    setShortlistAll: (
      state: ShortlistState,
      action: PayloadAction<ShortlistState>
    ) => {
      const newState: ShortlistState = {
        ...state,
        ...action.payload,
      }
      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    setShortlistAgent: (
      state: ShortlistState,
      action: PayloadAction<{ agentId: string }>
    ) => {
      const newState: ShortlistState = {
        ...state,
        agent: action.payload.agentId,
      }

      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    removeShortlistAgent: (state: ShortlistState) => {
      const newState: ShortlistState = {
        ...state,
        agent: '',
      }
      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    setShortlistDocuments: (
      state: ShortlistState,
      action: PayloadAction<string[]>
    ) => {
      const newState: ShortlistState = {
        ...state,
        documents: action.payload.map((doc: string) => doc),
      }

      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    filterShortlist: (
      state: ShortlistState,
      action: PayloadAction<
        | {
            propertyId: string
            configuration?: never
            type: ShortlistPropertyType.Unit
          }
        | {
            propertyId: string
            configuration?: PackageConfigurationInterface
            type: ShortlistPropertyType.Lot
          }
      >
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: state.shortlists?.filter((item) => {
          if (action.type === ShortlistPropertyType.Unit) {
            return item.propertyId !== action.payload.propertyId
          }

          const lotShortlistItem = item as LotShortlistInterface

          if (
            action.payload.propertyId === lotShortlistItem.propertyId &&
            (action.payload.configuration?.facade ?? '') ===
              (lotShortlistItem.configuration?.facade ?? '') &&
            (action.payload.configuration?.facadeColor ?? '') ===
              (lotShortlistItem.configuration?.facadeColor ?? '') &&
            (action.payload.configuration?.floorplan ?? '') ===
              (lotShortlistItem.configuration?.floorplan ?? '') &&
            (action.payload.configuration?.internalTheme ?? '') ===
              (lotShortlistItem.configuration?.internalTheme ?? '') &&
            (action.payload.configuration?.internalColorScheme ?? '') ===
              (lotShortlistItem.configuration?.internalColorScheme ?? '')
          ) {
            return false
          }

          return true
        }),
      }
      if (!newState.shortlists?.length) {
        localStorage.removeItem(LOCALSTORAGE_KEY)
        return { ...INITIAL_STATE }
      }
      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))
      return newState
    },
    filterShortlistByLot: (
      state: ShortlistState,
      action: PayloadAction<{
        propertyId: string
      }>
    ) => {
      const newState: ShortlistState = {
        ...state,
        shortlists: state.shortlists?.filter((item) => {
          if (action.payload.propertyId === item.propertyId) {
            return false
          }

          return true
        }),
      }

      if (!newState.shortlists?.length) {
        localStorage.removeItem(LOCALSTORAGE_KEY)
        return { ...INITIAL_STATE }
      }
      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState))

      return newState
    },
    removeShortlist: () => {
      localStorage.removeItem(LOCALSTORAGE_KEY)
      return { ...INITIAL_STATE }
    },
  },
})

export const {
  addShortlist,
  updateShortlist,
  setShortlists,
  setShortlistAll,
  filterShortlist,
  filterShortlistByLot,
  removeShortlist,
  setShortlistAgent,
  removeShortlistAgent,
  setShortlistDocuments,
} = shortlistSlice.actions

export const selectShortlist = (state: RootStateTypeExtra) => state.shortlist

export default shortlistSlice.reducer

import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { filterShortlist } from '@store/actionSlices/shortlist'
import {
  RootStateTypeExtra,
  SessionMap,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import { GenericModalCard as ModalCard } from '@components/modals'

import { Level } from '@api/building'

import UnitThumbnail from './unit-thumbnail'

interface UnitsListProps {
  session: SessionMap | undefined
  projectId: string
  shortlists: UnitShortlistInterface[]
  shortlistsData: Level[]
}

const UnitsList = ({
  session,
  projectId,
  shortlists,
  shortlistsData,
}: UnitsListProps) => {
  const dispatch = useDispatch()
  const isConnected = React.useMemo(
    () => !!session?.connected,
    [session?.connected]
  )
  const [actionableUnit, setActionableUnit] =
    React.useState<UnitShortlistInterface>()

  const handleRemoveShortlist = React.useCallback(
    (unit: UnitShortlistInterface) => () => {
      if (isConnected) return

      setActionableUnit(unit)
    },
    [isConnected]
  )

  const handleToggleActionableUnit = React.useCallback(() => {
    setActionableUnit(undefined)
  }, [])

  const handleRemove = React.useCallback(() => {
    if (actionableUnit) {
      dispatch(
        filterShortlist({
          propertyId: actionableUnit.propertyId,
          type: ShortlistPropertyType.Unit,
        })
      )
    }
  }, [actionableUnit])

  return (
    <>
      <p className="mb-[38px] text-center text-2xl font-semibold">
        {`${shortlists.length} Shortlisted ${
          shortlists.length > 1 ? 'Properties' : 'Property'
        }`}
      </p>
      <div className="no-scrollbar flex flex-auto flex-col gap-10 overflow-y-auto">
        {shortlists.map((item) =>
          item.type === ShortlistPropertyType.Unit ? (
            <UnitThumbnail
              key={item.propertyId}
              shortlistItem={item}
              projectId={projectId}
              levels={shortlistsData}
              onRemove={handleRemoveShortlist(item)}
            />
          ) : null
        )}
      </div>

      <ModalCard
        headerLabel="Remove from shortlist"
        toggle={handleToggleActionableUnit}
        cancelAction={() => {}}
        okAction={handleRemove}
        isVisible={!!actionableUnit}
        okLabel="Remove"
        cancelLabel="Cancel"
      >
        <div className="px-4 text-left">
          <p>Are you sure you want to remove this from your shortlist?</p>
        </div>
      </ModalCard>
    </>
  )
}

export default connect(
  ({
    projectIdentity: { projectId },
    firestore: { session },
  }: RootStateTypeExtra) => ({
    projectId,
    session,
  })
)(UnitsList)

import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RootStateTypeExtra } from '@src/store/types'

import { ConfigInterface } from '@store/actionSlices/projectConfig'

import { ProjectType } from '@utilities/adgroup-utilities/types/common'
import { hasEnvisionVR, hasSnaploader } from '@utilities/helper'

interface EmptyStateProps {
  projectConfig: ConfigInterface
}

const EmptyState = ({ projectConfig }: EmptyStateProps) => {
  const history = useHistory()
  const {
    threeDViewerUrl,
    snaploaderConfiguration,
    envisionVRConfiguration,
    type,
  } = projectConfig

  const getBuildingLink = React.useCallback((): string => {
    if (type === ProjectType.HouseAndLand) {
      return 'area-view-house-and-land'
    }

    if (hasSnaploader(snaploaderConfiguration)) {
      return 'snaploader-view'
    }

    if (hasEnvisionVR(envisionVRConfiguration)) {
      return '3d-building'
    }

    if (threeDViewerUrl !== '') {
      return 'areaview-3d'
    }

    return 'area-view'
  }, [snaploaderConfiguration, envisionVRConfiguration, type])

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <p className="mb-6 text-3xl font-semibold leading-none">
        Your Shortlist is empty
      </p>
      <p className="mb-11 text-[26px] leading-none">
        Add your favourite properties in it.
      </p>
      <button
        type="button"
        className="rounded-lg bg-mainColour px-12 py-5 text-[19px] font-semibold leading-none text-white"
        onClick={() => history.push(getBuildingLink())}
      >
        {type === ProjectType.HouseAndLand
          ? 'Explore Precinct'
          : 'Explore Residences'}
      </button>
    </div>
  )
}

export default connect(({ projectConfig }: RootStateTypeExtra) => ({
  projectConfig,
}))(EmptyState)

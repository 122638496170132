import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'

import { TokenPayloadInterface } from '@store/actionSlices/token'
import { RootStateTypeExtra } from '@store/types'

import { hasToken } from '@utilities/helper'

export type PublicRouteProps = {
  authenticatedPath: string
  token: TokenPayloadInterface
} & RouteProps

function PublicRoutes({
  authenticatedPath,
  token,
  ...routeProps
}: PublicRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    if (hasToken(token)) {
      setIsAuthenticated(true)
    }
  }, [token])

  if (!isAuthenticated) {
    return <Route {...routeProps} />
  }
  return <Redirect to={{ pathname: authenticatedPath }} />
}

export default connect(({ token }: RootStateTypeExtra) => ({
  token,
}))(PublicRoutes)

import React from 'react'

import { SessionMap } from '@store/types'

const useDetectDisconnect = (session: SessionMap | undefined) => {
  const lasConnectionStatus = React.useRef(!!session?.connected)
  const [hasDisconnected, setHasDisconnected] = React.useState(false)

  React.useEffect(() => {
    if (session?.connected) {
      lasConnectionStatus.current = true
      setHasDisconnected(false)
      return
    }
    if (lasConnectionStatus.current) {
      setHasDisconnected(true)
    }
  }, [session?.connected])

  return {
    hasDisconnected,
  }
}

export default useDetectDisconnect

import { CarouselProvider } from 'pure-react-carousel'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { setLocation } from '@store/actionSlices/location'
import type { RootStateTypeExtra, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FadeContainer from '@components/fade-container'
import GalleryHandler from '@components/gallery-handler'
import SlideTrigger from '@components/gallery-handler/slide-trigger'
import IdleTimeHandler from '@components/idle-time-handler'

import {
  LocationData,
  selectFromResult,
  useGetLocationQuery,
} from '@api/location'

export interface LocationProps {
  session: SessionMap | undefined
  projectName: string
  location: Array<LocationData>
}

const Location = ({ session, projectName, location }: LocationProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const [toggleControls, setToggleControls] = React.useState(true)
  const [activeLabel, setActiveLabel] = React.useState(0)
  const [isConnected, setIsConnected] = React.useState(false)
  const [transitionTime, setTransitionTime] = React.useState(1000)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const locationPayload = useGetLocationQuery(
    { projectName },
    { selectFromResult }
  )

  const handleGalleryHandlerClick = () => {
    setToggleControls((state) => !state)
  }

  React.useEffect(() => {
    const { locations } = locationPayload

    if (location.length === 0 && locations.length > 0) {
      dispatch(setLocation(locations))
    }
  }, [locationPayload, location])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        location: {
          galleryControl: {
            activeTabIndex: activeTabIndexFirebase,
            transitionTime: transitionTimeFirebase,
            isPlaying: isPlayingFirebase,
          },
        },
      } = session
      setIsConnected(connected)
      setActiveLabel(activeTabIndexFirebase)
      setTransitionTime(transitionTimeFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  const carouselData = React.useMemo(
    () =>
      location.length > 0
        ? {
            slides: location[activeLabel].Images.map((locContent) => ({
              id: locContent.id,
              src: locContent.imageSource,
            })),
            labels: location[activeLabel].Images.map((locContent) => ({
              label: locContent.label,
              sublabel: locContent.distance,
              src: locContent.imageSource,
              type: 'legacy',
              noSpliceUrl: false,
              gradiant: 0,
            })),
          }
        : { slides: [], labels: [] },
    [location, activeLabel]
  )

  return (
    <DataHandler
      payload={{
        ...locationPayload,
        data: location,
        apiData: locationPayload.locations,
      }}
    >
      <Container>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={location.length}
          dragEnabled={false}
          orientation="vertical"
          className="h-full ipad-mini:w-1100px"
          interval={transitionTime}
          isPlaying={isSlideShowPlaying}
          infinite
        >
          {!isConnected && location.length > 0 && (
            <IdleTimeHandler>
              <FadeContainer className="relative z-10" show={toggleControls}>
                <div className="absolute flex pt-8">
                  <SlideTrigger
                    type="text"
                    items={location.map((res: LocationData) => ({
                      text: res.label,
                    }))}
                    setCurrentSlide={setActiveLabel}
                    currentSlide={activeLabel}
                    dotContainerRef={dotContainerRef}
                    toggleControls={toggleControls}
                  />
                </div>
              </FadeContainer>
            </IdleTimeHandler>
          )}
          <TransitionGroup className="h-full">
            <CSSTransition
              key={activeLabel}
              classNames="transition-fade"
              timeout={500}
            >
              <GalleryHandler
                galleryName="location"
                galleryControl={session?.location.galleryControl}
                slides={carouselData.slides}
                labels={carouselData.labels}
                gradiant={0.1}
                isConnected={isConnected}
                dotType="thumbnail"
                onClick={handleGalleryHandlerClick}
                toggleControls={toggleControls}
                gallerySlideClassNames="transition-slide-gallerySlide"
                toggleBlurredBackground
              />
            </CSSTransition>
          </TransitionGroup>
        </CarouselProvider>
      </Container>
    </DataHandler>
  )
}

export default connect(
  ({
    firestore: { session },
    projectIdentity: { projectName },
    location,
  }: RootStateTypeExtra) => ({
    session,
    projectName,
    location,
  })
)(Location)
